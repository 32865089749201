"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BeesToJSON = exports.BeesFromJSONTyped = exports.BeesFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BeesFromJSON(json) {
    return BeesFromJSONTyped(json, false);
}
exports.BeesFromJSON = BeesFromJSON;
function BeesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.AbstractFormFromJSONTyped(json, ignoreDiscriminator),
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'activity': !runtime_1.exists(json, 'activity') ? undefined : json['activity'],
    };
}
exports.BeesFromJSONTyped = BeesFromJSONTyped;
function BeesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.AbstractFormToJSON(value),
        '_id': value._id,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'activity': value.activity,
    };
}
exports.BeesToJSON = BeesToJSON;
