import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader, Spinner, Table} from "reactstrap";
import {AdminApi, CSVID} from "client";
import {connect} from "react-redux";
import {addError} from "../redux/meta/MetaActions";

interface IProps {
	csvID: CSVID;
	isOpen: boolean;
	close: () => void;
	dispatch?: any;
}

const CSVViewer: React.FC<IProps> = (props) => {

	const [data, setData] = useState<object[]>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (props.isOpen) {
			getData().then().catch();
		}
	}, [props.isOpen])

	async function getData() {
		setLoading(true);
		try {
			const res = await new AdminApi().adminCsvIdGet({id: props.csvID});
			setData(res.data);
		} catch (err) {
			props.dispatch(addError(err));
		}
		setLoading(false);
	}

	function createHeader(item: string, i: number) {
		return (
			<th key={"header_" + i}>{item}</th>
		)
	}

	function createRow(item: object, i: number) {
		return (
			<tr key={"row_" + i}>
				{Object.entries(item).map(createCell)}
			</tr>
		)
	}

	function createCell([key, value]: [string, any], i: number) {
		return (
			<td key={key + "_" + i}>
				{value}
			</td>
		)
	}

	return (
		<Modal isOpen={props.isOpen} centered={true} toggle={props.close}>
			<ModalHeader toggle={props.close}>{props.csvID}</ModalHeader>
			{
				loading && <Spinner className="align-self-center"/>
			}
			{
				data && data?.length > 0 ?
					<Table hover size="sm" responsive>
						<thead>
							<tr>
								{Object.keys(data[0]).map(createHeader)}
							</tr>
						</thead>
						<tbody>
							{data.map(createRow)}
						</tbody>
					</Table> :
					<ModalBody>
						<p>No data found.</p>
					</ModalBody>
			}
		</Modal>
	)
}

export default connect()(CSVViewer);