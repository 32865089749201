"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeedControlResponseRowToJSON = exports.WeedControlResponseRowFromJSONTyped = exports.WeedControlResponseRowFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function WeedControlResponseRowFromJSON(json) {
    return WeedControlResponseRowFromJSONTyped(json, false);
}
exports.WeedControlResponseRowFromJSON = WeedControlResponseRowFromJSON;
function WeedControlResponseRowFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'date': !runtime_1.exists(json, 'date') ? undefined : json['date'],
        'field': !runtime_1.exists(json, 'field') ? undefined : json['field'],
        'crop': !runtime_1.exists(json, 'crop') ? undefined : json['crop'],
        'implement': !runtime_1.exists(json, 'implement') ? undefined : json['implement'],
        'category': !runtime_1.exists(json, 'category') ? undefined : _1.FieldWorkCategoryFromJSON(json['category']),
    };
}
exports.WeedControlResponseRowFromJSONTyped = WeedControlResponseRowFromJSONTyped;
function WeedControlResponseRowToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'date': value.date,
        'field': value.field,
        'crop': value.crop,
        'implement': value.implement,
        'category': _1.FieldWorkCategoryToJSON(value.category),
    };
}
exports.WeedControlResponseRowToJSON = WeedControlResponseRowToJSON;
