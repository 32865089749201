import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Bees, DefaultApi, DropdownLabel, Irrigation} from "client";
import {Input, Label} from "reactstrap";
import {connect} from "react-redux";
import DateTimePicker from "./DateTimePicker";
import {momentStringDownToMinutes} from "../../utils/formatters";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import NumberFormat from "react-number-format";

interface IProps {
	token?: string;
	dispatch?: any;
	values: Irrigation;

	onChange(key: keyof Irrigation, value: any);
}

const IrrigationForm: React.FC<IProps> = (props: IProps) => {

	const {token, values} = props;
	const [fields, setFields] = useState<string[]>([]);
	const [equipment, setEquipment] = useState<string[]>([]);
	const [implement, setImplement] = useState<string[]>([]);
	const [crops, setCrops] = useState<string[]>([]);

	useEffect(() => {
		getDropDownValues().then().catch();
	}, []);

	async function getDropDownValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const fieldsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Field}});
			setFields(fieldsRes);

			const equipmentRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Equipment}});
			setEquipment(equipmentRes);

			const implementRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Implement}});
			setImplement(implementRes);

			const cropsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Crop}});
			setCrops(cropsRes);

		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function dateOnChangeHelper(key: keyof Irrigation): (e) => void {
		return (e) => {
			props.onChange(key, e);
		};
	}

	function onChangeHelper(key: keyof Irrigation): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			// @ts-ignore
			props.onChange(key, e.target.value);
		};
	}

	return (
		<div>
			<div className="mb-3">
				<Label>Start Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.startTime)}
				</p>
				<DateTimePicker time={values.startTime} onChange={dateOnChangeHelper("startTime")}/>
			</div>

			<div className="mb-3">
				<Label>End Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.endTime)}
				</p>
				<DateTimePicker time={values.endTime} onChange={dateOnChangeHelper("endTime")}/>
			</div>

			<div className="mb-3">
				<Label>Field</Label>
				<Input type="select" value={values.field} onChange={onChangeHelper("field")}>
					<option value="">Make a selection...</option>
					<hr/>
					{fields.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Equipment</Label>
				<Input type="select" value={values.equipment} onChange={onChangeHelper("equipment")}>
					<option value="">Make a selection...</option>
					<hr/>
					{equipment.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Implement</Label>
				<Input type="select" value={values.implement} onChange={onChangeHelper("implement")}>
					<option value="">Make a selection...</option>
					<hr/>
					{implement.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Crop</Label>
				<Input type="select" value={values.crop} onChange={onChangeHelper("crop")}>
					<option value="">Make a selection...</option>
					<hr/>
					{crops.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Notes</Label>
				<Input type="textarea" value={values.notes} placeholder="Notes" onChange={onChangeHelper("notes")}/>
			</div>

		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(IrrigationForm);
