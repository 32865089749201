"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class AssetApi extends runtime.BaseAPI {
    /**
     * Uploads an asset to the asset manager for admins.
     */
    async createAssetManagerAssetRaw(requestParameters) {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset', 'Required parameter requestParameters.asset was null or undefined when calling createAssetManagerAsset.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.asset !== undefined) {
            formParams.append('asset', requestParameters.asset);
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description);
        }
        if (requestParameters.urlExtension !== undefined) {
            formParams.append('urlExtension', requestParameters.urlExtension);
        }
        const response = await this.request({
            path: `/asset/create_asset_manager_asset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Uploads an asset to the asset manager for admins.
     */
    async createAssetManagerAsset(requestParameters) {
        await this.createAssetManagerAssetRaw(requestParameters);
    }
    /**
     * Gets all eligible assets for the asset manager.
     */
    async getAssetManagerAssetsRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.showHidden !== undefined) {
            queryParameters['showHidden'] = requestParameters.showHidden;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/asset/get_asset_manager_assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.AssetFromJSON));
    }
    /**
     * Gets all eligible assets for the asset manager.
     */
    async getAssetManagerAssets(requestParameters) {
        const response = await this.getAssetManagerAssetsRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to hide an asset
     */
    async hideAssetRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/asset/hide_asset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.HideAssetBodyToJSON(requestParameters.hideAssetBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to hide an asset
     */
    async hideAsset(requestParameters) {
        await this.hideAssetRaw(requestParameters);
    }
    /**
     * Used to show a hidden asset
     */
    async showAssetRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/asset/show_asset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ShowAssetBodyToJSON(requestParameters.showAssetBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to show a hidden asset
     */
    async showAsset(requestParameters) {
        await this.showAssetRaw(requestParameters);
    }
    /**
     * Uploads an asset to the asset manager for admins.
     */
    async updateAssetManagerAssetRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.id !== undefined) {
            formParams.append('_id', requestParameters.id);
        }
        if (requestParameters.asset !== undefined) {
            formParams.append('asset', requestParameters.asset);
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description);
        }
        const response = await this.request({
            path: `/asset/update_asset_manager_asset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Uploads an asset to the asset manager for admins.
     */
    async updateAssetManagerAsset(requestParameters) {
        await this.updateAssetManagerAssetRaw(requestParameters);
    }
    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetRaw(requestParameters) {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension', 'Required parameter requestParameters.urlExtension was null or undefined when calling viewAsset.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/asset/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     * pipes the asset from Google Storage.
     */
    async viewAsset(requestParameters) {
        const response = await this.viewAssetRaw(requestParameters);
        return await response.value();
    }
    /**
     * Gets the base64 of a particular asset.
     */
    async viewAssetBase64Raw(requestParameters) {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension', 'Required parameter requestParameters.urlExtension was null or undefined when calling viewAssetBase64.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/asset_base64/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AssetBase64ResponseFromJSON(jsonValue));
    }
    /**
     * Gets the base64 of a particular asset.
     */
    async viewAssetBase64(requestParameters) {
        const response = await this.viewAssetBase64Raw(requestParameters);
        return await response.value();
    }
}
exports.AssetApi = AssetApi;
