import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Container, Label, Input} from "reactstrap";
import React, {ChangeEventHandler, useEffect, useState} from "react";
import { DropdownLabel, AdminApi, DefaultApi } from "client";
import SelectOptions from "../components/SelectOptionsFactory";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import DropdownEditor from "../components/DropdownEditor";

interface IProps {
	token?: string;
	dispatch?: any;
}

const CustomDropdownsManager: React.FC<IProps> = (props) => {

	const [selectedDropDown, setSelectedDropDown] = useState<DropdownLabel>();
	const [values, setValues] = useState<string[]>();
	const enumValues = Object.values(DropdownLabel);

	useEffect(() => {
		getDropdownValues().then().catch()
	}, [selectedDropDown])

	async function getDropdownValues() {
		if (!selectedDropDown) {
			return;
		}

		props.dispatch(incrementLoading());
		try {
			const values = await new DefaultApi(getConfig(props.token)).dropdownListPost({dropdownListBody: {dropdownLabel: selectedDropDown, alphabeticalOrder: false}})
			setValues(values);
		} catch (err) {
			props.dispatch(addError(err.json()))
		}
		props.dispatch(decrementLoading());
	}

	async function updateDropdownValues() {
		if (!selectedDropDown || !values) {
			return;
		}
		props.dispatch(incrementLoading());
		try {
			await new AdminApi(getConfig(props.token)).adminUpdateDropdownPut({dropdown: {label: selectedDropDown, values}})
		} catch (err) {
			props.dispatch(addError(err.json()))
		}
		props.dispatch(decrementLoading());
	}

	const onDropdownChange: ChangeEventHandler<HTMLInputElement> = e => {
		setSelectedDropDown(e.target.value as DropdownLabel)
	}

	return (
		<Container className="my-4">
			<div className="mb-4">
				<h1>Custom Dropdowns Manager</h1>
				<p>
					In the application there is a number of dropdowns which are custom, they have dynamic content pulled from the server.
					This page will allow editting of these dropdowns. In the app, values will be returned in alphabetical order.
				</p>
			</div>
			<div>
				<Label>
					Dropdown Value
				</Label>
				<Input type="select" value={selectedDropDown} onChange={onDropdownChange} placeholder="Select Dropdown" className="mb-3">
					<option value="" selected disabled>Select a Dropdown</option>
					<hr/>
					<SelectOptions strings={enumValues}/>
				</Input>
			</div>
			{
				values &&
				<div className="mt-4">
					<DropdownEditor values={values} onChange={setValues} onSubmit={updateDropdownValues}/>
				</div>
			}
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	};
})(CustomDropdownsManager);
