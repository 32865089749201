"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelMapToJSON = exports.ModelMapFromJSONTyped = exports.ModelMapFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ModelMapFromJSON(json) {
    return ModelMapFromJSONTyped(json, false);
}
exports.ModelMapFromJSON = ModelMapFromJSON;
function ModelMapFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'assets': (json['assets'].map(_1.AssetFromJSON)),
    };
}
exports.ModelMapFromJSONTyped = ModelMapFromJSONTyped;
function ModelMapToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'assets': (value.assets.map(_1.AssetToJSON)),
    };
}
exports.ModelMapToJSON = ModelMapToJSON;
