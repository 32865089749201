"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumberVerificationToJSON = exports.PhoneNumberVerificationFromJSONTyped = exports.PhoneNumberVerificationFromJSON = void 0;
const runtime_1 = require("../runtime");
function PhoneNumberVerificationFromJSON(json) {
    return PhoneNumberVerificationFromJSONTyped(json, false);
}
exports.PhoneNumberVerificationFromJSON = PhoneNumberVerificationFromJSON;
function PhoneNumberVerificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'phoneNumber': json['phoneNumber'],
        'verificationCode': json['verificationCode'],
        'messageSID': json['messageSID'],
        'creationDate': json['creationDate'],
        'completionTime': !runtime_1.exists(json, 'completionTime') ? undefined : json['completionTime'],
        'completionSha': !runtime_1.exists(json, 'completionSha') ? undefined : json['completionSha'],
    };
}
exports.PhoneNumberVerificationFromJSONTyped = PhoneNumberVerificationFromJSONTyped;
function PhoneNumberVerificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'phoneNumber': value.phoneNumber,
        'verificationCode': value.verificationCode,
        'messageSID': value.messageSID,
        'creationDate': value.creationDate,
        'completionTime': value.completionTime,
        'completionSha': value.completionSha,
    };
}
exports.PhoneNumberVerificationToJSON = PhoneNumberVerificationToJSON;
