"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserIDBodyToJSON = exports.UserIDBodyFromJSONTyped = exports.UserIDBodyFromJSON = void 0;
function UserIDBodyFromJSON(json) {
    return UserIDBodyFromJSONTyped(json, false);
}
exports.UserIDBodyFromJSON = UserIDBodyFromJSON;
function UserIDBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userID': json['userID'],
    };
}
exports.UserIDBodyFromJSONTyped = UserIDBodyFromJSONTyped;
function UserIDBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userID': value.userID,
    };
}
exports.UserIDBodyToJSON = UserIDBodyToJSON;
