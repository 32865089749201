import {FieldWorkCategory} from "client";

export interface ILabelValuePair {
	value: any;
	label: string;
}

export function fieldCategoryLookup(category: FieldWorkCategory): string {
	switch (category) {
		case FieldWorkCategory.BOGRENOVATION:
			return "Bog Renovation";
		case FieldWorkCategory.COMPOSTSPREAD:
			return "Compost Spread";
		case FieldWorkCategory.COMPOSTTURN:
			return "Compost Turn";
		case FieldWorkCategory.CRANBERRYBOGMAINTENANCE:
			return "Cranberry Bog Maintenance";
		case FieldWorkCategory.CROPMAINTENANCE:
			return "Crop Maintenance";
		case FieldWorkCategory.CUSTOMWORK:
			return "Custom Work";
		case FieldWorkCategory.FALLTILLAGE:
			return "Fall Tillage";
		case FieldWorkCategory.FERTILIZE:
			return "Fertilize";
		case FieldWorkCategory.HARVEST:
			return "Harvest";
		case FieldWorkCategory.HARVESTPROP:
			return "Harvest Prep";
		case FieldWorkCategory.LOADORHAUL:
			return "Load / Haul";
		case FieldWorkCategory.MISCWORK:
			return "Misc. Farm Work";
		case FieldWorkCategory.PLANTING:
			return "Planting";
		case FieldWorkCategory.SPRINGTILLAGE:
			return "Spring Tillage";
		case FieldWorkCategory.SHOPWORK:
			return "Shop Work";
		default:
			return "";
	}
}

export function generateFieldCategoryDropDownList(): Array<ILabelValuePair> {
	return [
		{value: FieldWorkCategory.BOGRENOVATION, label: fieldCategoryLookup(FieldWorkCategory.BOGRENOVATION)},
		{value: FieldWorkCategory.COMPOSTSPREAD, label: fieldCategoryLookup(FieldWorkCategory.COMPOSTSPREAD)},
		{value: FieldWorkCategory.COMPOSTTURN, label: fieldCategoryLookup(FieldWorkCategory.COMPOSTTURN)},
		{value: FieldWorkCategory.CRANBERRYBOGMAINTENANCE, label: fieldCategoryLookup(FieldWorkCategory.CRANBERRYBOGMAINTENANCE)},
		{value: FieldWorkCategory.CROPMAINTENANCE, label: fieldCategoryLookup(FieldWorkCategory.CROPMAINTENANCE)},
		{value: FieldWorkCategory.CUSTOMWORK, label: fieldCategoryLookup(FieldWorkCategory.CUSTOMWORK)},
		{value: FieldWorkCategory.FALLTILLAGE, label: fieldCategoryLookup(FieldWorkCategory.FALLTILLAGE)},
		{value: FieldWorkCategory.FERTILIZE, label: fieldCategoryLookup(FieldWorkCategory.FERTILIZE)},
		{value: FieldWorkCategory.HARVEST, label: fieldCategoryLookup(FieldWorkCategory.HARVEST)},
		{value: FieldWorkCategory.HARVESTPROP, label: fieldCategoryLookup(FieldWorkCategory.HARVESTPROP)},
		{value: FieldWorkCategory.LOADORHAUL, label: fieldCategoryLookup(FieldWorkCategory.LOADORHAUL)},
		{value: FieldWorkCategory.MISCWORK, label: fieldCategoryLookup(FieldWorkCategory.MISCWORK)},
		{value: FieldWorkCategory.PLANTING, label: fieldCategoryLookup(FieldWorkCategory.PLANTING)},
		{value: FieldWorkCategory.SPRINGTILLAGE, label: fieldCategoryLookup(FieldWorkCategory.SPRINGTILLAGE)},
		{value: FieldWorkCategory.SHOPWORK, label: fieldCategoryLookup(FieldWorkCategory.SHOPWORK)},
	]
}
