"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormsApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class FormsApi extends runtime.BaseAPI {
    /**
     * Delete a specific bees\' entry
     */
    async userFormsBeesDeleteRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/bees`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.BeesToJSON(requestParameters.bees),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a specific bees\' entry
     */
    async userFormsBeesDelete(requestParameters) {
        await this.userFormsBeesDeleteRaw(requestParameters);
    }
    /**
     * Create bees entry
     */
    async userFormsBeesPostRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/bees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.BeesToJSON(requestParameters.bees),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Create bees entry
     */
    async userFormsBeesPost(requestParameters) {
        await this.userFormsBeesPostRaw(requestParameters);
    }
    /**
     * update bees\' entry
     */
    async userFormsBeesPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/bees`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.BeesToJSON(requestParameters.bees),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update bees\' entry
     */
    async userFormsBeesPut(requestParameters) {
        await this.userFormsBeesPutRaw(requestParameters);
    }
    /**
     * Delete a specific compostTemperature entry
     */
    async userFormsCompostTemperatureDeleteRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/compost_temperature`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CompostTemperatureToJSON(requestParameters.compostTemperature),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a specific compostTemperature entry
     */
    async userFormsCompostTemperatureDelete(requestParameters) {
        await this.userFormsCompostTemperatureDeleteRaw(requestParameters);
    }
    /**
     * Create Compost Temperature entry
     */
    async userFormsCompostTemperaturePostRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/compost_temperature`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CompostTemperatureToJSON(requestParameters.compostTemperature),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Create Compost Temperature entry
     */
    async userFormsCompostTemperaturePost(requestParameters) {
        await this.userFormsCompostTemperaturePostRaw(requestParameters);
    }
    /**
     * update compostTemperature entry
     */
    async userFormsCompostTemperaturePutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/compost_temperature`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CompostTemperatureToJSON(requestParameters.compostTemperature),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update compostTemperature entry
     */
    async userFormsCompostTemperaturePut(requestParameters) {
        await this.userFormsCompostTemperaturePutRaw(requestParameters);
    }
    /**
     * Delete a specific Field Work entry
     */
    async userFormsFieldWorkDeleteRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/field_work`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.FieldWorkToJSON(requestParameters.fieldWork),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a specific Field Work entry
     */
    async userFormsFieldWorkDelete(requestParameters) {
        await this.userFormsFieldWorkDeleteRaw(requestParameters);
    }
    /**
     * Create Field Work entry
     */
    async userFormsFieldWorkPostRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/field_work`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.FieldWorkToJSON(requestParameters.fieldWork),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Create Field Work entry
     */
    async userFormsFieldWorkPost(requestParameters) {
        await this.userFormsFieldWorkPostRaw(requestParameters);
    }
    /**
     * update Field Work entry
     */
    async userFormsFieldWorkPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/field_work`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.FieldWorkToJSON(requestParameters.fieldWork),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update Field Work entry
     */
    async userFormsFieldWorkPut(requestParameters) {
        await this.userFormsFieldWorkPutRaw(requestParameters);
    }
    /**
     * update Field Work lot numbers to strings.
     */
    async userFormsFieldWorkUpdateLotNumbersPutRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/field_work/update_lot_numbers`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update Field Work lot numbers to strings.
     */
    async userFormsFieldWorkUpdateLotNumbersPut() {
        await this.userFormsFieldWorkUpdateLotNumbersPutRaw();
    }
    /**
     * Delete a specific Harvest Record entry
     */
    async userFormsHarvestRecordDeleteRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/harvest_record`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.HarvestRecordToJSON(requestParameters.harvestRecord),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a specific Harvest Record entry
     */
    async userFormsHarvestRecordDelete(requestParameters) {
        await this.userFormsHarvestRecordDeleteRaw(requestParameters);
    }
    /**
     * Create Harvest Record entry
     */
    async userFormsHarvestRecordPostRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/harvest_record`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.HarvestRecordToJSON(requestParameters.harvestRecord),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Create Harvest Record entry
     */
    async userFormsHarvestRecordPost(requestParameters) {
        await this.userFormsHarvestRecordPostRaw(requestParameters);
    }
    /**
     * update Harvest Record entry
     */
    async userFormsHarvestRecordPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/harvest_record`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.HarvestRecordToJSON(requestParameters.harvestRecord),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update Harvest Record entry
     */
    async userFormsHarvestRecordPut(requestParameters) {
        await this.userFormsHarvestRecordPutRaw(requestParameters);
    }
    /**
     * Delete a specific irrigation entry
     */
    async userFormsIrrigationDeleteRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/irrigation`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IrrigationToJSON(requestParameters.irrigation),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a specific irrigation entry
     */
    async userFormsIrrigationDelete(requestParameters) {
        await this.userFormsIrrigationDeleteRaw(requestParameters);
    }
    /**
     * Create Irrigation entry
     */
    async userFormsIrrigationPostRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/irrigation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IrrigationToJSON(requestParameters.irrigation),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Create Irrigation entry
     */
    async userFormsIrrigationPost(requestParameters) {
        await this.userFormsIrrigationPostRaw(requestParameters);
    }
    /**
     * update irrigation entry
     */
    async userFormsIrrigationPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/irrigation`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IrrigationToJSON(requestParameters.irrigation),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update irrigation entry
     */
    async userFormsIrrigationPut(requestParameters) {
        await this.userFormsIrrigationPutRaw(requestParameters);
    }
    /**
     * Delete a specific notes entry
     */
    async userFormsNotesDeleteRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/notes`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.NotesToJSON(requestParameters.notes),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a specific notes entry
     */
    async userFormsNotesDelete(requestParameters) {
        await this.userFormsNotesDeleteRaw(requestParameters);
    }
    /**
     * Create notes entry
     */
    async userFormsNotesPostRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.NotesToJSON(requestParameters.notes),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Create notes entry
     */
    async userFormsNotesPost(requestParameters) {
        await this.userFormsNotesPostRaw(requestParameters);
    }
    /**
     * update notes entry
     */
    async userFormsNotesPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/notes`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.NotesToJSON(requestParameters.notes),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update notes entry
     */
    async userFormsNotesPut(requestParameters) {
        await this.userFormsNotesPutRaw(requestParameters);
    }
    /**
     * Delete a specific sprayApp entry
     */
    async userFormsSprayApplicationDeleteRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/spray_application`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.SprayAppToJSON(requestParameters.sprayApp),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a specific sprayApp entry
     */
    async userFormsSprayApplicationDelete(requestParameters) {
        await this.userFormsSprayApplicationDeleteRaw(requestParameters);
    }
    /**
     * Create Spray Application entry
     */
    async userFormsSprayApplicationPostRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/spray_application`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.SprayAppToJSON(requestParameters.sprayApp),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Create Spray Application entry
     */
    async userFormsSprayApplicationPost(requestParameters) {
        await this.userFormsSprayApplicationPostRaw(requestParameters);
    }
    /**
     * update sprayApp entry
     */
    async userFormsSprayApplicationPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/forms/spray_application`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.SprayAppToJSON(requestParameters.sprayApp),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update sprayApp entry
     */
    async userFormsSprayApplicationPut(requestParameters) {
        await this.userFormsSprayApplicationPutRaw(requestParameters);
    }
}
exports.FormsApi = FormsApi;
