import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import React, {useEffect, useState} from "react";
import {Button, Container} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {AdminApi, AdminCustomCsvPostRequest, CustomCSV} from "client";
import getConfig from "../utils/getConfig";
import CustomCSVInputModal from "../components/CustomCSVInputModal";
import CustomCSVFeed from "../components/CustomCSVFeed";

interface IProps {
	token?: string;
	dispatch?: any;
}

const CustomCSVManager: React.FC<IProps> = (props) => {

	const [openNewCSVModal, setOpenNewCSVModal] = useState(false);
	const [newCSV, setNewCSV] = useState<Partial<AdminCustomCsvPostRequest>>({});
	const [csvs, setCSVs] = useState<CustomCSV[]>([]);

	useEffect(() => {
		getCSVs().then().catch();
	}, [])

	async function addNewCSV() {
		props.dispatch(incrementLoading());
		try {
			await new AdminApi(getConfig(props.token)).adminCustomCsvPost(newCSV as AdminCustomCsvPostRequest);
			setNewCSV({});
			closeNewCSVModalFunction();
			await getCSVs();
		} catch (err) {
			props.dispatch(addError(err));
		}
		props.dispatch(decrementLoading());
	}

	async function getCSVs() {
		props.dispatch(incrementLoading());
		try {
			const res = await new AdminApi(getConfig(props.token)).adminCustomCsvListGet();
			setCSVs(res);
		} catch (err) {
			props.dispatch(addError(err))
		}
		props.dispatch(decrementLoading());
	}

	const openNewCSVModalFunction = () => setOpenNewCSVModal(true);
	const closeNewCSVModalFunction = () => setOpenNewCSVModal(false);

	return (
		<React.Fragment>
			<CustomCSVInputModal open={openNewCSVModal} close={closeNewCSVModalFunction} value={newCSV} onChange={setNewCSV} onSubmit={addNewCSV}/>
			<Container className="my-4">
				<div className="mb-4">
					<h1>
						Custom CSV Manager
					</h1>

					<Button color="primary" onClick={openNewCSVModalFunction} disabled={openNewCSVModal}>
						Add New CSV
					</Button>
				</div>
				<CustomCSVFeed csvs={csvs} onUpdate={getCSVs}/>
			</Container>
		</React.Fragment>
	)
}

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(CustomCSVManager);