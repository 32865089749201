"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldWorkCategoryToJSON = exports.FieldWorkCategoryFromJSONTyped = exports.FieldWorkCategoryFromJSON = exports.FieldWorkCategory = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var FieldWorkCategory;
(function (FieldWorkCategory) {
    FieldWorkCategory["BOGRENOVATION"] = "BOG_RENOVATION";
    FieldWorkCategory["COMPOSTSPREAD"] = "COMPOST_SPREAD";
    FieldWorkCategory["COMPOSTTURN"] = "COMPOST_TURN";
    FieldWorkCategory["CROPMAINTENANCE"] = "CROP_MAINTENANCE";
    FieldWorkCategory["FALLTILLAGE"] = "FALL_TILLAGE";
    FieldWorkCategory["FERTILIZE"] = "FERTILIZE";
    FieldWorkCategory["HARVEST"] = "HARVEST";
    FieldWorkCategory["HARVESTPROP"] = "HARVEST_PROP";
    FieldWorkCategory["PLANTING"] = "PLANTING";
    FieldWorkCategory["SPRINGTILLAGE"] = "SPRING_TILLAGE";
    FieldWorkCategory["SHOPWORK"] = "SHOP_WORK";
    FieldWorkCategory["CUSTOMWORK"] = "CUSTOM_WORK";
    FieldWorkCategory["MISCWORK"] = "MISC_WORK";
    FieldWorkCategory["CRANBERRYBOGMAINTENANCE"] = "CRANBERRY_BOG_MAINTENANCE";
    FieldWorkCategory["LOADORHAUL"] = "LOAD_OR_HAUL";
})(FieldWorkCategory = exports.FieldWorkCategory || (exports.FieldWorkCategory = {}));
function FieldWorkCategoryFromJSON(json) {
    return FieldWorkCategoryFromJSONTyped(json, false);
}
exports.FieldWorkCategoryFromJSON = FieldWorkCategoryFromJSON;
function FieldWorkCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.FieldWorkCategoryFromJSONTyped = FieldWorkCategoryFromJSONTyped;
function FieldWorkCategoryToJSON(value) {
    return value;
}
exports.FieldWorkCategoryToJSON = FieldWorkCategoryToJSON;
