import React from "react";
import moment, {Moment} from "moment";
import {Col, Input, Row} from "reactstrap";

interface IProps {
	time: number;
	onChange(key: any): any;
}

const DateTimePicker: React.FC<IProps> = (props: IProps) => {

	const {time} = props;

	function onDateChange(e): void {
		const n: Moment = moment(e.target.value, "YYYY-MM-DD");
		const t: Moment = moment(time);
		t.set({
			year: n.year(),
			month: n.month(),
			date: n.date(),
		});
		props.onChange(t.valueOf());
	}

	function convertHour(hour: number, mer: "AM" | "PM"): number {
		if (mer === "AM") {
			return hour === 12 ? 0 : hour;
		} else {
			return hour === 12 ? 12 : hour + 12;
		}
	}

	function hourOnChange(e): void {
		const h: number = convertHour(parseInt(e.target.value), moment(time).format("A") as any);
		const t: Moment = moment(time);

		t.set({hour: h});
		props.onChange(t.valueOf());
	}

	function onMinuteChange(e): void {
		const m: number = parseInt(e.target.value);
		const t: Moment = moment(time);
		t.set({minute: m});
		props.onChange(t.valueOf());
	}

	function onMeridiemChange(e): void {
		const mer: "AM" | "PM" = e.target.value;
		let h: number = parseInt(moment(time).format("h"));
		if (mer === "AM" && h === 12) {
			h = 0;
		} else if (mer === "PM" && h !== 12) {
			h = h + 12;
		}

		const t: Moment = moment(time);

		t.set({hour: h});
		props.onChange(t.valueOf());
	}

	return (
		<div>
			<Input type="date" value={moment(time).format("YYYY-MM-DD")} placeholder="Start Time" onChange={onDateChange} className="mb-3"/>
			<Row>
				<Col xs={4}>
					<Input
						type="select"
						value={parseInt(moment(time).format("h"))}
						onChange={hourOnChange}
					>
						<option value={12}>12</option>
						<option value={1}>1</option>
						<option value={2}>2</option>
						<option value={3}>3</option>
						<option value={4}>4</option>
						<option value={5}>5</option>
						<option value={6}>6</option>
						<option value={7}>7</option>
						<option value={8}>8</option>
						<option value={9}>9</option>
						<option value={10}>10</option>
						<option value={11}>11</option>
					</Input>
				</Col>
				<Col xs={4}>
					<Input
						type="select"
						value={parseInt(moment(time).format("mm"))}
						onChange={onMinuteChange}
					>
						<option value={0}>00</option>
						<option value={15}>15</option>
						<option value={30}>30</option>
						<option value={45}>45</option>
					</Input>
				</Col>
				<Col xs={4}>
					<Input
						type="select"
						value={moment(time).format("A")}
						onChange={onMeridiemChange}
					>
						<option value="AM">AM</option>
						<option value="PM">PM</option>
					</Input>
				</Col>
			</Row>
		</div>
	);
};

export default DateTimePicker;
