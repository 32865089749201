"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetToJSON = exports.AssetFromJSONTyped = exports.AssetFromJSON = void 0;
const runtime_1 = require("../runtime");
function AssetFromJSON(json) {
    return AssetFromJSONTyped(json, false);
}
exports.AssetFromJSON = AssetFromJSON;
function AssetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'hidden': !runtime_1.exists(json, 'hidden') ? undefined : json['hidden'],
        'owner': !runtime_1.exists(json, 'owner') ? undefined : json['owner'],
        'urlExtension': !runtime_1.exists(json, 'urlExtension') ? undefined : json['urlExtension'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'mimeType': !runtime_1.exists(json, 'mimeType') ? undefined : json['mimeType'],
        'created': !runtime_1.exists(json, 'created') ? undefined : json['created'],
        'lastUpdated': !runtime_1.exists(json, 'lastUpdated') ? undefined : json['lastUpdated'],
        'size': !runtime_1.exists(json, 'size') ? undefined : json['size'],
    };
}
exports.AssetFromJSONTyped = AssetFromJSONTyped;
function AssetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'hidden': value.hidden,
        'owner': value.owner,
        'urlExtension': value.urlExtension,
        'url': value.url,
        'name': value.name,
        'description': value.description,
        'mimeType': value.mimeType,
        'created': value.created,
        'lastUpdated': value.lastUpdated,
        'size': value.size,
    };
}
exports.AssetToJSON = AssetToJSON;
