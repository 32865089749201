"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SprayAppToJSON = exports.SprayAppFromJSONTyped = exports.SprayAppFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SprayAppFromJSON(json) {
    return SprayAppFromJSONTyped(json, false);
}
exports.SprayAppFromJSON = SprayAppFromJSON;
function SprayAppFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.AbstractFormFromJSONTyped(json, ignoreDiscriminator),
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'equipment': !runtime_1.exists(json, 'equipment') ? undefined : json['equipment'],
        'implement': !runtime_1.exists(json, 'implement') ? undefined : json['implement'],
        'crop': !runtime_1.exists(json, 'crop') ? undefined : json['crop'],
        'weatherCondition': !runtime_1.exists(json, 'weatherCondition') ? undefined : json['weatherCondition'],
        'sprayDetails': !runtime_1.exists(json, 'sprayDetails') ? undefined : (json['sprayDetails'].map(_1.SprayAppDetailsFromJSON)),
        'sprayFields': !runtime_1.exists(json, 'sprayFields') ? undefined : (json['sprayFields'].map(_1.SprayAppFieldsFromJSON)),
    };
}
exports.SprayAppFromJSONTyped = SprayAppFromJSONTyped;
function SprayAppToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.AbstractFormToJSON(value),
        '_id': value._id,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'equipment': value.equipment,
        'implement': value.implement,
        'crop': value.crop,
        'weatherCondition': value.weatherCondition,
        'sprayDetails': value.sprayDetails === undefined ? undefined : (value.sprayDetails.map(_1.SprayAppDetailsToJSON)),
        'sprayFields': value.sprayFields === undefined ? undefined : (value.sprayFields.map(_1.SprayAppFieldsToJSON)),
    };
}
exports.SprayAppToJSON = SprayAppToJSON;
