import React, {useState} from "react";
import {FormEntriesResponse, FormIdentifier, FormsApi} from "client";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {momentStringDownToMinutes} from "../utils/formatters";
import {formIdentifierParser} from "../utils/formIdentifierParser";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import GenericSuccessModal from "./GenericSuccessModal";

interface IProps {
	token?: string;
	dispatch?: any;
	submission: FormEntriesResponse;

	onCancel(): void;

	onDone(): void;
}

const FormDeletingModal: React.FC<IProps> = (props: IProps) => {

	const {token, submission} = props;
	const [showSuccess, setShowSuccess] = useState(false);

	async function confirmDelete(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			switch (submission.formType.formIdentifier) {
				case FormIdentifier.FIELDWORK:
					await new FormsApi(getConfig(token)).userFormsFieldWorkDelete({fieldWork: submission.formType});
					break;
				case FormIdentifier.SPRAYAPP:
					await new FormsApi(getConfig(token)).userFormsSprayApplicationDelete({sprayApp: submission.formType});
					break;
				case FormIdentifier.COMPOSTTEMPERATURE:
					await new FormsApi(getConfig(token)).userFormsCompostTemperatureDelete({compostTemperature: submission.formType});
					break;
				case FormIdentifier.IRRIGATION:
					await new FormsApi(getConfig(token)).userFormsIrrigationDelete({irrigation: submission.formType});
					break;
				case FormIdentifier.HARVESTRECORD:
					await new FormsApi(getConfig(token)).userFormsHarvestRecordDelete({harvestRecord: submission.formType});
					break;
				case FormIdentifier.BEES:
					await new FormsApi(getConfig(token)).userFormsBeesDelete({bees: submission.formType});
					break;
				case FormIdentifier.NOTES:
					await new FormsApi(getConfig(token)).userFormsNotesDelete({notes: submission.formType});
					break;
				default:
					break;
			}

			setShowSuccess(true);
		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function handleOnDone(): void {
		setShowSuccess(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={handleOnDone}
				body="Submission deleted successfully."
			/>

			<Modal
				isOpen={submission && Object.keys(submission).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={props.onCancel}
			>
				{(submission && Object.keys(submission).length > 0) && (
					<React.Fragment>
						<ModalHeader toggle={props.onCancel}>Confirm Deletion of Submission</ModalHeader>

						<ModalBody>
							<p>
								Are you sure you want to delete this submission?
							</p>

							<div className="mb-3">
								<Label className="text-secondary">Employee</Label>
								<p>
									{submission.userName}
								</p>
							</div>

							<div className="mb-3">
								<Label className="text-secondary">Form Type</Label>
								<p>
									{formIdentifierParser(submission.formType.formIdentifier)}
								</p>
							</div>

							<div className="mb-3">
								<Label className="text-secondary">Submitted</Label>
								<p>
									{momentStringDownToMinutes(submission.formType.creationTime)}
								</p>
							</div>

						</ModalBody>

						<ModalFooter>
							<Button color="link" onClick={props.onCancel}>
								Cancel
							</Button>
							<Button color="danger" onClick={confirmDelete}>
								Confirm Delete
							</Button>
						</ModalFooter>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(FormDeletingModal);
