import {FormIdentifier} from "client";

export function formIdentifierParser(form: FormIdentifier): string {
	switch (form) {
		case FormIdentifier.FIELDWORK:
			return "Field Work";
		case FormIdentifier.SPRAYAPP:
			return "Spray Application";
		case FormIdentifier.COMPOSTTEMPERATURE:
			return "Compost Temperature";
		case FormIdentifier.IRRIGATION:
			return "Irrigation";
		case FormIdentifier.HARVESTRECORD:
			return "Harvest Record";
		case FormIdentifier.BEES:
			return "Bees";
		case FormIdentifier.NOTES:
			return "Notes";
		default:
			return form;
	}
}
