"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormEntriesResponseToJSON = exports.FormEntriesResponseFromJSONTyped = exports.FormEntriesResponseFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FormEntriesResponseFromJSON(json) {
    return FormEntriesResponseFromJSONTyped(json, false);
}
exports.FormEntriesResponseFromJSON = FormEntriesResponseFromJSON;
function FormEntriesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'formType': !runtime_1.exists(json, 'formType') ? undefined : _1.FormTypeFromJSON(json['formType']),
        'userName': !runtime_1.exists(json, 'userName') ? undefined : json['userName'],
    };
}
exports.FormEntriesResponseFromJSONTyped = FormEntriesResponseFromJSONTyped;
function FormEntriesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'formType': _1.FormTypeToJSON(value.formType),
        'userName': value.userName,
    };
}
exports.FormEntriesResponseToJSON = FormEntriesResponseToJSON;
