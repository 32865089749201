import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {CSVID, AdminApi} from "client";
import {connect} from "react-redux";
import CSVViewer from "./CSVViewer";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {IStore} from "../redux/defaultStore";

interface IProps {
	open: boolean;
	close: () => void;
	csvID: CSVID;
	dispatch?: any;
	token?: string;
	// onSubmit: () => Promise<void>;
}

const CSVInputModal: React.FC<IProps> = (props) => {

	const [csv, setCSV] = useState<Blob>();
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const setOpen = () => setIsOpen(true);
	const setClose = () => setIsOpen(false);

	useEffect(() => {
		setCSV(undefined);
	}, [props.open])

	async function uploadNewCSV() {
		props.dispatch(incrementLoading());
		try {
			await new AdminApi(getConfig(props.token)).adminCsvPut({csv, id: props.csvID});
			props.close();
		} catch (err) {
			props.dispatch(addError(err));
		}
		props.dispatch(decrementLoading());
	}

	function onFileUploaded(e) {
		setCSV(e.target.files[0]);
	}

	return (
		<React.Fragment>
			<CSVViewer isOpen={isOpen} close={setClose} csvID={props.csvID}/>
			<Modal isOpen={props.open} centered={true} toggle={props.close}>
				<ModalHeader toggle={props.close}>Edit CSV</ModalHeader>
				<ModalBody>
					<Label className="mt-1">ID</Label>
					<Input value={props.csvID} disabled={true}/>

					<Label className="mt-1">CSV File</Label>
					<Input className="mb-1" type="file" onChange={onFileUploaded}/>

					<ModalFooter>
						<ButtonGroup>
							<Button onClick={uploadNewCSV} color="primary" disabled={!csv}>Submit</Button>
							<Button onClick={setOpen} color="primary">View Current CSV</Button>
						</ButtonGroup>
					</ModalFooter>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(CSVInputModal);