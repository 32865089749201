"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormTypeToJSON = exports.FormTypeFromJSONTyped = exports.FormTypeFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FormTypeFromJSON(json) {
    return FormTypeFromJSONTyped(json, false);
}
exports.FormTypeFromJSON = FormTypeFromJSON;
function FormTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'owner': !runtime_1.exists(json, 'owner') ? undefined : json['owner'],
        'creationTime': !runtime_1.exists(json, 'creationTime') ? undefined : json['creationTime'],
        'lastTouched': !runtime_1.exists(json, 'lastTouched') ? undefined : json['lastTouched'],
        'notes': json['notes'],
        'formIdentifier': !runtime_1.exists(json, 'formIdentifier') ? undefined : _1.FormIdentifierFromJSON(json['formIdentifier']),
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'activity': !runtime_1.exists(json, 'activity') ? undefined : json['activity'],
        'equipment': !runtime_1.exists(json, 'equipment') ? undefined : json['equipment'],
        'implement': !runtime_1.exists(json, 'implement') ? undefined : json['implement'],
        'crop': !runtime_1.exists(json, 'crop') ? undefined : json['crop'],
        'weatherCondition': !runtime_1.exists(json, 'weatherCondition') ? undefined : json['weatherCondition'],
        'sprayDetails': !runtime_1.exists(json, 'sprayDetails') ? undefined : (json['sprayDetails'].map(_1.SprayAppDetailsFromJSON)),
        'sprayFields': !runtime_1.exists(json, 'sprayFields') ? undefined : (json['sprayFields'].map(_1.SprayAppFieldsFromJSON)),
        'date': json['date'],
        'compostPiles': !runtime_1.exists(json, 'compostPiles') ? undefined : (json['compostPiles'].map(_1.CompostTempPerPileFromJSON)),
        'field': !runtime_1.exists(json, 'field') ? undefined : json['field'],
        'truck': !runtime_1.exists(json, 'truck') ? undefined : json['truck'],
        'quantity': !runtime_1.exists(json, 'quantity') ? undefined : json['quantity'],
        'units': !runtime_1.exists(json, 'units') ? undefined : json['units'],
        'loadTime': !runtime_1.exists(json, 'loadTime') ? undefined : json['loadTime'],
        'destination': !runtime_1.exists(json, 'destination') ? undefined : json['destination'],
        'category': !runtime_1.exists(json, 'category') ? undefined : _1.FieldWorkCategoryFromJSON(json['category']),
        'compostPile': !runtime_1.exists(json, 'compostPile') ? undefined : json['compostPile'],
        'applicationRate': !runtime_1.exists(json, 'applicationRate') ? undefined : json['applicationRate'],
        'passesOver': !runtime_1.exists(json, 'passesOver') ? undefined : json['passesOver'],
        'fertilizeInput': !runtime_1.exists(json, 'fertilizeInput') ? undefined : json['fertilizeInput'],
        'seed': !runtime_1.exists(json, 'seed') ? undefined : json['seed'],
        'unit': !runtime_1.exists(json, 'unit') ? undefined : json['unit'],
        'ratePerAcre': !runtime_1.exists(json, 'ratePerAcre') ? undefined : json['ratePerAcre'],
        'lotNumber': !runtime_1.exists(json, 'lotNumber') ? undefined : json['lotNumber'],
        'acres': !runtime_1.exists(json, 'acres') ? undefined : json['acres'],
        'loadCount': !runtime_1.exists(json, 'loadCount') ? undefined : json['loadCount'],
        'commodity': !runtime_1.exists(json, 'commodity') ? undefined : json['commodity'],
        'checkedEquipment': !runtime_1.exists(json, 'checkedEquipment') ? undefined : json['checkedEquipment'],
    };
}
exports.FormTypeFromJSONTyped = FormTypeFromJSONTyped;
function FormTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'owner': value.owner,
        'creationTime': value.creationTime,
        'lastTouched': value.lastTouched,
        'notes': value.notes,
        'formIdentifier': _1.FormIdentifierToJSON(value.formIdentifier),
        'startTime': value.startTime,
        'endTime': value.endTime,
        'activity': value.activity,
        'equipment': value.equipment,
        'implement': value.implement,
        'crop': value.crop,
        'weatherCondition': value.weatherCondition,
        'sprayDetails': value.sprayDetails === undefined ? undefined : (value.sprayDetails.map(_1.SprayAppDetailsToJSON)),
        'sprayFields': value.sprayFields === undefined ? undefined : (value.sprayFields.map(_1.SprayAppFieldsToJSON)),
        'date': value.date,
        'compostPiles': value.compostPiles === undefined ? undefined : (value.compostPiles.map(_1.CompostTempPerPileToJSON)),
        'field': value.field,
        'truck': value.truck,
        'quantity': value.quantity,
        'units': value.units,
        'loadTime': value.loadTime,
        'destination': value.destination,
        'category': _1.FieldWorkCategoryToJSON(value.category),
        'compostPile': value.compostPile,
        'applicationRate': value.applicationRate,
        'passesOver': value.passesOver,
        'fertilizeInput': value.fertilizeInput,
        'seed': value.seed,
        'unit': value.unit,
        'ratePerAcre': value.ratePerAcre,
        'lotNumber': value.lotNumber,
        'acres': value.acres,
        'loadCount': value.loadCount,
        'commodity': value.commodity,
        'checkedEquipment': value.checkedEquipment,
    };
}
exports.FormTypeToJSON = FormTypeToJSON;
