import React, {ChangeEventHandler} from "react";
import {Button, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {CustomCSV, AdminCustomCsvPostRequest} from "client";

interface IProps {
	open: boolean;
	close: () => void;
	value: Partial<AdminCustomCsvPostRequest>;
	onChange: (v: Partial<AdminCustomCsvPostRequest>) => void;
	onSubmit: () => Promise<void>;
}

const CustomCSVInputModal: React.FC<IProps> = (props) => {

	function createOnChange(key: keyof AdminCustomCsvPostRequest): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			props.onChange({
				...props.value,
				[key]: e.target.value,
			})
		}
	}

	function onFileUploaded(e) {
		props.onChange({
			...props.value,
			csv: e.target.files[0]
		})
	}

	return (
		<Modal isOpen={props.open} centered={true} toggle={props.close}>
			<ModalHeader toggle={props.close}>Custom CSV</ModalHeader>
			<ModalBody>
				<Label className="mt-1">Name</Label>
				<Input value={props.value.name} onChange={createOnChange("name")}/>

				<Label className="mt-1">Description</Label>
				<Input type="textarea" value={props.value.description} onChange={createOnChange("description")}/>

				<Label className="mt-1">CSV File</Label>
				<Input type="file" onChange={onFileUploaded}/>

				<ModalFooter>
					<Button onClick={props.onSubmit} color="primary">Submit</Button>
				</ModalFooter>
			</ModalBody>
		</Modal>
	)
};

export default CustomCSVInputModal;