"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class UserApi extends runtime.BaseAPI {
    /**
     * Used by users to change their passwords.
     */
    async changePasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/change_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangePasswordBodyToJSON(requestParameters.changePasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to change their passwords.
     */
    async changePassword(requestParameters) {
        await this.changePasswordRaw(requestParameters);
    }
    /**
     * Used to sign up a user
     */
    async signUpRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/sign_up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.SignUpBodyToJSON(requestParameters.signUpBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to sign up a user
     */
    async signUp(requestParameters) {
        await this.signUpRaw(requestParameters);
    }
    /**
     * Get daily summary for the particular user given day/month/year
     */
    async userDailySummaryPostRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/daily_summary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.DailySummaryBodyToJSON(requestParameters.dailySummaryBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.DailySummaryResponseFromJSON(jsonValue));
    }
    /**
     * Get daily summary for the particular user given day/month/year
     */
    async userDailySummaryPost(requestParameters) {
        const response = await this.userDailySummaryPostRaw(requestParameters);
        return await response.value();
    }
    /**
     * Get all the spray application entries for a given field.
     */
    async userGetSprayApplicationEntriesGetRaw(requestParameters) {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field', 'Required parameter requestParameters.field was null or undefined when calling userGetSprayApplicationEntriesGet.');
        }
        const queryParameters = {};
        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/get_spray_application_entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.SprayApplicationResponseRowFromJSON));
    }
    /**
     * Get all the spray application entries for a given field.
     */
    async userGetSprayApplicationEntriesGet(requestParameters) {
        const response = await this.userGetSprayApplicationEntriesGetRaw(requestParameters);
        return await response.value();
    }
    /**
     * Given \'FormIdentifier\', it returns the last submitted form for the registered user
     */
    async userLastFormForUserGetRaw(requestParameters) {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId', 'Required parameter requestParameters.formId was null or undefined when calling userLastFormForUserGet.');
        }
        const queryParameters = {};
        if (requestParameters.formId !== undefined) {
            queryParameters['formId'] = requestParameters.formId;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/lastFormForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.FormTypeFromJSON(jsonValue));
    }
    /**
     * Given \'FormIdentifier\', it returns the last submitted form for the registered user
     */
    async userLastFormForUserGet(requestParameters) {
        const response = await this.userLastFormForUserGetRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to login a user account. Returns a token if successful
     */
    async userLoginRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/user_login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.LoginBodyToJSON(requestParameters.loginBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TokenFromJSON(jsonValue));
    }
    /**
     * Used to login a user account. Returns a token if successful
     */
    async userLogin(requestParameters) {
        const response = await this.userLoginRaw(requestParameters);
        return await response.value();
    }
    /**
     * Get a list of all the admins.
     */
    async userUserDashboardGetRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user_dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.FormTypeFromJSON));
    }
    /**
     * Get a list of all the admins.
     */
    async userUserDashboardGet() {
        const response = await this.userUserDashboardGetRaw();
        return await response.value();
    }
    /**
     * Get information on the logged in user (self)
     */
    async userUserInfoGetRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.UserFromJSON(jsonValue));
    }
    /**
     * Get information on the logged in user (self)
     */
    async userUserInfoGet() {
        const response = await this.userUserInfoGetRaw();
        return await response.value();
    }
    /**
     * Get a list of all the users.
     */
    async userUserListGetRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.UserFromJSON));
    }
    /**
     * Get a list of all the users.
     */
    async userUserListGet() {
        const response = await this.userUserListGetRaw();
        return await response.value();
    }
    /**
     * Get all the field works that have the planting activity or crop maintenance activity given a field.
     */
    async userWeedControlGetRaw(requestParameters) {
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field', 'Required parameter requestParameters.field was null or undefined when calling userWeedControlGet.');
        }
        const queryParameters = {};
        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/weed_control`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.WeedControlResponseRowFromJSON));
    }
    /**
     * Get all the field works that have the planting activity or crop maintenance activity given a field.
     */
    async userWeedControlGet(requestParameters) {
        const response = await this.userWeedControlGetRaw(requestParameters);
        return await response.value();
    }
}
exports.UserApi = UserApi;
