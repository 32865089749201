"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormIdentifierToJSON = exports.FormIdentifierFromJSONTyped = exports.FormIdentifierFromJSON = exports.FormIdentifier = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var FormIdentifier;
(function (FormIdentifier) {
    FormIdentifier["BEES"] = "BEES";
    FormIdentifier["SPRAYAPP"] = "SPRAY_APP";
    FormIdentifier["COMPOSTTEMPERATURE"] = "COMPOST_TEMPERATURE";
    FormIdentifier["HARVESTRECORD"] = "HARVEST_RECORD";
    FormIdentifier["IRRIGATION"] = "IRRIGATION";
    FormIdentifier["FIELDWORK"] = "FIELD_WORK";
    FormIdentifier["NOTES"] = "NOTES";
})(FormIdentifier = exports.FormIdentifier || (exports.FormIdentifier = {}));
function FormIdentifierFromJSON(json) {
    return FormIdentifierFromJSONTyped(json, false);
}
exports.FormIdentifierFromJSON = FormIdentifierFromJSON;
function FormIdentifierFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.FormIdentifierFromJSONTyped = FormIdentifierFromJSONTyped;
function FormIdentifierToJSON(value) {
    return value;
}
exports.FormIdentifierToJSON = FormIdentifierToJSON;
