import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
	CompostTempPerPile,
	FormEntriesResponse,
	FormIdentifier,
	FormType,
	SprayAppDetails,
	SprayAppFields
} from "client";
import {momentStringDownToMinutes, parseTrueFalseToYesNo} from "../utils/formatters";
import {Button} from "reactstrap";
import {fieldCategoryLookup} from "../utils/fieldCategoryLookup";
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';

export interface IFormSubmissionsTableRow extends FormType {
	raw: FormEntriesResponse;
	userName: string;
	onEdit(): void;
	onDelete(): void;
}

interface IProps {
	data: Array<IFormSubmissionsTableRow>;
	formType: FormIdentifier;
}

const FormSubmissionsTable: React.FC<IProps> = (props: IProps) => {

	const {data, formType} = props;

	return (
		<BootstrapTable
			bootstrap4={true}
			striped={false}
			hover={true}
			condensed={true}
			bordered={true}
			wrapperClasses="table-responsive"
			rowStyle={{whiteSpace: "nowrap"}}
			columns={getColumns(formType)}
			keyField={"_id"}
			data={data}
			noDataIndication={"No Submissions yet."}
			defaultSorted={[{dataField: "creationTime", order: "desc"}]}
			pagination={paginationFactory({
				paginationSize: 5,
				alwaysShowAllBtns: true, // Always show next and previous button
				firstPageText: 'First',
				prePageText: 'Back',
				nextPageText: 'Next',
				lastPageText: 'Last',
				nextPageTitle: 'First page',
				prePageTitle: 'Pre page',
				firstPageTitle: 'Next page',
				lastPageTitle: 'Last page',
				showTotal: true,
				paginationTotalRenderer: (from, to, size) => {return (<span className="ml-3">{`Showing ${from} to ${to} of ${size} entries`}</span>)},
				sizePerPageList: [
					{text: "5 per page", value: 5},
					{text: "10 per page", value: 10},
					{text: "15 per page", value: 15},
					{text: "20 per page", value: 20},
					{text: "25 per page", value: 25},
					{text: "50 per page", value: 50},
				],
			})}
			filter={ filterFactory() }
		/>
	);
};

const idCol = {
	dataField: "_id",
	text: "ID",
	sort: false,
	filter: textFilter({ caseSensitive: true }),
	headerClasses: "th-filter-select-helper",
};

const userCol = {
	dataField: "userName",
	text: "User",
	sort: true,
};

const submittedCol = {
	dataField: "creationTime",
	text: "Submitted",
	sort: true,
	formatter: momentStringDownToMinutes,
	classes: "text-right",
};

const startTimeCol = {
	dataField: "startTime",
	text: "Start Time",
	sort: true,
	formatter: momentStringDownToMinutes,
	classes: "text-right",
};

const endTimeCol = {
	dataField: "endTime",
	text: "End Time",
	sort: true,
	formatter: momentStringDownToMinutes,
	classes: "text-right",
};

const fieldCol = {
	dataField: "field",
	text: "Field",
	sort: true,
};

const equipmentCol = {
	dataField: "equipment",
	text: "Equipment",
	sort: true,
};

const implementCol = {
	dataField: "implement",
	text: "Implement",
	sort: true,
};

const cropCol = {
	dataField: "crop",
	text: "Crop",
	sort: true,
};

const categoryCol = {
	dataField: "category",
	text: "Category",
	sort: true,
	formatter: fieldCategoryLookup,
};

const compostPileCol = {
	dataField: "compostPile",
	text: "Compost Pile",
	sort: true,
};

const applicationRateCol = {
	dataField: "applicationRate",
	text: "Application\nRate",
	sort: true,
};

const passesOverCol = {
	dataField: "passesOver",
	text: "Passes\nOver",
	sort: true,
};

const checkedEquipmentCol = {
	dataField: "checkedEquipment",
	text: "Checked\nEquipment",
	sort: true,
	formatter: parseTrueFalseToYesNo,
};

const fertilizeInputCol = {
	dataField: "fertilizeInput",
	text: "Input",
	sort: true,
};

const seedCol = {
	dataField: "seed",
	text: "Seed",
	sort: true,
};

const unitCol = {
	dataField: "unit",
	text: "Units",
	sort: true,
};

const ratePerAcreCol = {
	dataField: "ratePerAcre",
	text: "Rate Per\nAcre",
	sort: true,
};

const lotNumberCol = {
	dataField: "lotNumber",
	text: "Lot\nNumber",
	sort: true,
};

const acresCol = {
	dataField: "acres",
	text: "Acres",
	sort: true,
};

const notesCol = {
	dataField: "notes",
	text: "Notes",
	sort: true,
	classes: "td-max-300",
	formatter: (cell) => (<p className="td-max-300">{cell}</p>),
};

const activityCol = {
	dataField: "activity",
	text: "Activity",
	sort: true,
};

const loadTimeCol = {
	dataField: "loadTime",
	text: "Load Time",
	sort: true,
	formatter: momentStringDownToMinutes,
	classes: "text-right",
};

const truckCol = {
	dataField: "truck",
	text: "Truck",
	sort: true,
};

const quantityCol = {
	dataField: "quantity",
	text: "Quantity",
	sort: true,
};

const unitsCol = {
	dataField: "units",
	text: "Units",
	sort: true,
};

const destinationCol = {
	dataField: "destination",
	text: "Destination",
	sort: true,
};

const dateCol = {
	dataField: "date",
	text: "Date",
	sort: true,
	formatter: momentStringDownToMinutes,
	classes: "text-right",
};

const compostPilesCol = {
	dataField: "compostPiles",
	text: "Compost Piles",
	sort: true,
	formatter: makeCompostPileCells,
};

const weatherConditionCol = {
	dataField: "weatherCondition",
	text: "Weather Condition",
	sort: true,
};

const sprayDetailsCol = {
	dataField: "sprayDetails",
	text: "Spray Details",
	sort: true,
	formatter: makeSprayDetailCells,
};

const sprayFieldsCol = {
	dataField: "sprayFields",
	text: "Spray Fields",
	sort: true,
	formatter: makeSprayFieldCells,
};

const loadCountCol = {
	dataField: "loadCount",
	text: "Load Count",
	sort: false,
};

const commodityCol = {
	dataField: "commodity",
	text: "Commodity",
	sort: false,
};

const actionsCol = {
	dataField: "actions",
	text: "Actions",
	sort: false,
	formatter: makeEditButton,
};

function getColumns(formType: FormIdentifier): any[] {
	switch (formType) {
		case FormIdentifier.FIELDWORK:
			return [idCol, userCol, submittedCol, startTimeCol, endTimeCol, fieldCol, equipmentCol, implementCol, cropCol, categoryCol, compostPileCol, fertilizeInputCol, applicationRateCol, passesOverCol, seedCol, unitCol, ratePerAcreCol, lotNumberCol, acresCol, loadCountCol, commodityCol, notesCol, checkedEquipmentCol, actionsCol];
		case FormIdentifier.BEES:
			return [idCol, userCol, submittedCol, startTimeCol, endTimeCol, activityCol, notesCol, actionsCol];
		case FormIdentifier.IRRIGATION:
			return [idCol, userCol, submittedCol, startTimeCol, endTimeCol, fieldCol, equipmentCol, implementCol, cropCol, notesCol, actionsCol];
		case FormIdentifier.HARVESTRECORD:
			return [idCol, userCol, submittedCol, startTimeCol, loadTimeCol, fieldCol, cropCol, truckCol, quantityCol, unitsCol, destinationCol, notesCol, actionsCol];
		case FormIdentifier.COMPOSTTEMPERATURE:
			return [idCol, userCol, submittedCol, dateCol, compostPilesCol, notesCol, actionsCol];
		case FormIdentifier.SPRAYAPP:
			return [idCol, userCol, submittedCol, startTimeCol, endTimeCol, equipmentCol, implementCol, cropCol, weatherConditionCol, sprayDetailsCol, sprayFieldsCol, notesCol, actionsCol];
		case FormIdentifier.NOTES:
			return [idCol, userCol, submittedCol, notesCol, actionsCol];
		default:
			return [idCol, userCol, submittedCol, startTimeCol, endTimeCol];
	}
}

function makeCompostPileCells(cell: Array<CompostTempPerPile> = [], row: IFormSubmissionsTableRow): any {
	return cell.map((ct: CompostTempPerPile, i: number) => {
		return (
			<div>
				{i > 0 && (
					<hr className="my-2"/>
				)}
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Compost Pile
					</span>
					<span>
						{ct.compostPile}
					</span>
				</p>
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Temperature 1
					</span>
					<span>
						{ct.temp1}
					</span>
				</p>
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Temperature 2
					</span>
					<span>
						{ct.temp2}
					</span>
				</p>
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Temperature 3
					</span>
					<span>
						{ct.temp3}
					</span>
				</p>
			</div>
		);
	});
}

function makeSprayDetailCells(cell: Array<SprayAppDetails> = [], row: IFormSubmissionsTableRow): any {
	return cell.map((sad: SprayAppDetails, i: number) => {
		return (
			<div>
				{i > 0 && (
					<hr className="my-2"/>
				)}
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Spray Product
					</span>
					<span>
						{sad.sprayProduct}
					</span>
				</p>
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Units
					</span>
					<span>
						{sad.units}
					</span>
				</p>
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Rate Per Acre
					</span>
					<span>
						{sad.ratePerAcre}
					</span>
				</p>
			</div>
		);
	});
}

function makeSprayFieldCells(cell: Array<SprayAppFields> = [], row: IFormSubmissionsTableRow): any {
	return cell.map((saf: SprayAppFields, i: number) => {
		return (
			<div>
				{i > 0 && (
					<hr className="my-2"/>
				)}
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Field
					</span>
					<span>
						{saf.field}
					</span>
				</p>
				<p className="d-flex justify-content-between mb-0">
					<span className="mr-4">
						Number of Acres
					</span>
					<span>
						{saf.acres}
					</span>
				</p>
			</div>
		);
	});
}

function makeEditButton(cell: any, row: IFormSubmissionsTableRow): JSX.Element {

	function onEditHelper(): void {
		row.onEdit();
	}

	function onDeleteHelper(): void {
		row.onDelete();
	}

	return (
		<div>
			<Button color="primary" onClick={onEditHelper} className="mr-3">
				Edit
			</Button>

			<Button color="danger" onClick={onDeleteHelper} className="mr-3">
				Delete
			</Button>
		</div>
	)
}

export default FormSubmissionsTable;
