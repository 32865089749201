import React, {ChangeEventHandler, ReactNode, useEffect, useState} from "react";
import {IStore} from "../../redux/defaultStore";
import {connect} from "react-redux";
import {CompostTempPerPile, DefaultApi, DropdownLabel, SprayApp, SprayAppDetails, SprayAppFields} from "client";
import {momentStringDownToMinutes} from "../../utils/formatters";
import DateTimePicker from "./DateTimePicker";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import NumberFormat from "react-number-format";
import cloneDeep from "lodash/cloneDeep";

interface IProps {
	token?: string;
	dispatch?: any;
	values: SprayApp;

	onChange(key: keyof SprayApp, value: any);
}

const SprayApplicationForm: React.FC<IProps> = (props: IProps) => {

	const {token, values} = props;
	const [equipment, setEquipment] = useState<string[]>([]);
	const [implement, setImplement] = useState<string[]>([]);
	const [crop, setCrop] = useState<string[]>([]);
	const [weather, setWeather] = useState<string[]>([]);
	const [sprayProducts, setSprayProducts] = useState<string[]>([]);
	const [units, setUnits] = useState<string[]>([]);
	const [fields, setFields] = useState<string[]>([]);

	useEffect(() => {
		getDropDownValues().then().catch();
	}, []);

	async function getDropDownValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const equipmentRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Equipment}});
			setEquipment(equipmentRes);

			const implementRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Implement}});
			setImplement(implementRes);

			const cropRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Crop}});
			setCrop(cropRes);

			const weatherRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Weather}});
			setWeather(weatherRes);

			const sprayProductsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.SprayProduct}});
			setSprayProducts(sprayProductsRes);

			const unitsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Units}});
			setUnits(unitsRes);

			const fieldsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Field}});
			setFields(fieldsRes);

		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function dateOnChangeHelper(key: keyof SprayApp): (e) => void {
		return (e) => {
			props.onChange(key, e);
		};
	}

	function onChangeHelper(key: keyof SprayApp): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			// @ts-ignore
			props.onChange(key, e.target.value);
		};
	}

	function onSprayDetailsChangeHelper(key: keyof SprayAppDetails, value: string, index: number): void {
		const newDetails: Array<SprayAppDetails> = cloneDeep(values.sprayDetails);
		// @ts-ignore
		newDetails[index][key] = value;
		props.onChange("sprayDetails", newDetails);
	}

	function addSprayDetail(): void {
		const newDetails: Array<SprayAppDetails> = cloneDeep(values.sprayDetails);
		newDetails.push({
			sprayProduct: "",
			units: "",
			ratePerAcre: undefined,
		});
		props.onChange("sprayDetails", newDetails);
	}

	function removeSprayDetail(): void {
		const newDetails: Array<SprayAppDetails> = cloneDeep(values.sprayDetails);
		newDetails.splice(newDetails.length - 1, 1);
		props.onChange("sprayDetails", newDetails);
	}

	function makeDynamicSprayDetails(details: Array<SprayAppDetails> = []): ReactNode {
		return details.map((detail: SprayAppDetails, i: number) => {

			function onDropDownChangeHelper(key: keyof SprayAppDetails): (e) => void {
				return (e) => {
					onSprayDetailsChangeHelper(key, e.target.value, i);
				};
			}

			function onRatePerAcreChangeHelper(e): void {
				onSprayDetailsChangeHelper("ratePerAcre", e.floatValue, i);
			}

			return (
				<div>
					{i > 0 && (
						<hr className="my-2"/>
					)}

					<div className="mb-2">
						<Label>Spray Product</Label>
						<Input type="select" value={detail.sprayProduct}
							   onChange={onDropDownChangeHelper("sprayProduct")}>
							<option value="">Make a selection...</option>
							<hr/>
							{sprayProducts.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>
					<div className="mb-2">
						<Label>Units</Label>
						<Input type="select" value={detail.units} onChange={onDropDownChangeHelper("units")}>
							<option value="">Make a selection...</option>
							<hr/>
							{units.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>
					<div className="mb-3">
						<Label>Rate Per Acre</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Rate per Acre"
							value={detail.ratePerAcre}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onRatePerAcreChangeHelper}
							allowNegative={false}
						/>
					</div>
				</div>
			);
		});
	}

	function onFieldsChangeHelper(key: keyof SprayAppFields, value: string, index: number): void {
		const newFields: Array<SprayAppFields> = cloneDeep(values.sprayFields);
		// @ts-ignore
		newFields[index][key] = value;
		props.onChange("sprayFields", newFields);
	}

	function addField(): void {
		const newFields: Array<SprayAppFields> = cloneDeep(values.sprayFields);
		newFields.push({
			field: "",
			acres: undefined,
		});
		props.onChange("sprayFields", newFields);
	}

	function removeField(): void {
		const newFields: Array<SprayAppFields> = cloneDeep(values.sprayFields);
		newFields.splice(newFields.length - 1, 1);
		props.onChange("sprayFields", newFields);
	}

	function makeDynamicFields(_fields: Array<SprayAppFields> = []): ReactNode {
		return _fields.map((field: SprayAppFields, i: number) => {

			function onFieldChangeHelper(e): void {
				onFieldsChangeHelper("field", e.target.value, i);
			}

			function onAcresChangeHelper(e): void {
				onFieldsChangeHelper("acres", e.floatValue, i);
			}

			return (
				<div>
					{i > 0 && (
						<hr className="my-2"/>
					)}

					<div className="mb-2">
						<Label>Field</Label>
						<Input type="select" value={field.field} onChange={onFieldChangeHelper}>
							<option value="">Make a selection...</option>
							<hr/>
							{fields.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>
					<div className="mb-3">
						<Label>Acres</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Number of Acres"
							value={field.acres}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onAcresChangeHelper}
							allowNegative={false}
						/>
					</div>
				</div>
			);
		});
	}

	return (
		<div>
			<div className="mb-3">
				<Label>Start Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.startTime)}
				</p>
				<DateTimePicker time={values.startTime} onChange={dateOnChangeHelper("startTime")}/>
			</div>

			<div className="mb-3">
				<Label>End Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.endTime)}
				</p>
				<DateTimePicker time={values.endTime} onChange={dateOnChangeHelper("endTime")}/>
			</div>

			<div className="mb-3">
				<Label>Equipment</Label>
				<Input type="select" value={values.equipment} onChange={onChangeHelper("equipment")}>
					<option value="">Make a selection...</option>
					<hr/>
					{equipment.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Implement</Label>
				<Input type="select" value={values.implement} onChange={onChangeHelper("implement")}>
					<option value="">Make a selection...</option>
					<hr/>
					{implement.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Crop</Label>
				<Input type="select" value={values.crop} onChange={onChangeHelper("crop")}>
					<option value="">Make a selection...</option>
					<hr/>
					{crop.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Weather Condition</Label>
				<Input type="select" value={values.weatherCondition} onChange={onChangeHelper("weatherCondition")}>
					<option value="">Make a selection...</option>
					<hr/>
					{weather.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div>
				<div className="mb-3">
					<Label><h5>Spray Details</h5></Label>
					{makeDynamicSprayDetails(values.sprayDetails)}
				</div>

				<div className="d-flex justify-content-between my-3">
					<Button color="info" className="px-4 text-light" onClick={addSprayDetail}>
						+
					</Button>

					<Button color="info" className="px-4 text-light" onClick={removeSprayDetail}>
						-
					</Button>
				</div>
			</div>

			<div>
				<div className="mb-3">
					<Label><h5>Fields Applied</h5></Label>
					{makeDynamicFields(values.sprayFields)}
				</div>

				<div className="d-flex justify-content-between my-3">
					<Button color="info" className="px-4 text-light" onClick={addField}>
						+
					</Button>

					<Button color="info" className="px-4 text-light" onClick={removeField}>
						-
					</Button>
				</div>
			</div>

			<div className="mb-3">
				<Label>Notes</Label>
				<Input type="textarea" value={values.notes} placeholder="Notes" onChange={onChangeHelper("notes")}/>
			</div>

		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(SprayApplicationForm);
