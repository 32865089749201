"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineObject2ToJSON = exports.InlineObject2FromJSONTyped = exports.InlineObject2FromJSON = void 0;
const runtime_1 = require("../runtime");
function InlineObject2FromJSON(json) {
    return InlineObject2FromJSONTyped(json, false);
}
exports.InlineObject2FromJSON = InlineObject2FromJSON;
function InlineObject2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'csv': !runtime_1.exists(json, 'csv') ? undefined : json['csv'],
    };
}
exports.InlineObject2FromJSONTyped = InlineObject2FromJSONTyped;
function InlineObject2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'name': value.name,
        'description': value.description,
        'csv': value.csv,
    };
}
exports.InlineObject2ToJSON = InlineObject2ToJSON;
