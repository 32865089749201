"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompostTempPerPileToJSON = exports.CompostTempPerPileFromJSONTyped = exports.CompostTempPerPileFromJSON = void 0;
const runtime_1 = require("../runtime");
function CompostTempPerPileFromJSON(json) {
    return CompostTempPerPileFromJSONTyped(json, false);
}
exports.CompostTempPerPileFromJSON = CompostTempPerPileFromJSON;
function CompostTempPerPileFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'compostPile': !runtime_1.exists(json, 'compostPile') ? undefined : json['compostPile'],
        'temp1': !runtime_1.exists(json, 'temp1') ? undefined : json['temp1'],
        'temp2': !runtime_1.exists(json, 'temp2') ? undefined : json['temp2'],
        'temp3': !runtime_1.exists(json, 'temp3') ? undefined : json['temp3'],
    };
}
exports.CompostTempPerPileFromJSONTyped = CompostTempPerPileFromJSONTyped;
function CompostTempPerPileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'compostPile': value.compostPile,
        'temp1': value.temp1,
        'temp2': value.temp2,
        'temp3': value.temp3,
    };
}
exports.CompostTempPerPileToJSON = CompostTempPerPileToJSON;
