import moment from "moment";

function momentStringFromMilliseconds(ms: number): string {
	return moment(ms).format("MMM Do YYYY");
}

function momentStringDownToMinutes(ms: number): string {
	return moment(ms).format("MMM Do YYYY, h:mmA");
}

function parseTrueFalseToYesNo(condition: boolean): string {
	return condition ? "Yes" : "No";
}

export {
	momentStringFromMilliseconds,
	momentStringDownToMinutes,
	parseTrueFalseToYesNo,
}
