"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DailySummaryItemToJSON = exports.DailySummaryItemFromJSONTyped = exports.DailySummaryItemFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DailySummaryItemFromJSON(json) {
    return DailySummaryItemFromJSONTyped(json, false);
}
exports.DailySummaryItemFromJSON = DailySummaryItemFromJSON;
function DailySummaryItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'formIdentifier': _1.FormIdentifierFromJSON(json['formIdentifier']),
        'ms': json['ms'],
        'field': !runtime_1.exists(json, 'field') ? undefined : json['field'],
    };
}
exports.DailySummaryItemFromJSONTyped = DailySummaryItemFromJSONTyped;
function DailySummaryItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'formIdentifier': _1.FormIdentifierToJSON(value.formIdentifier),
        'ms': value.ms,
        'field': value.field,
    };
}
exports.DailySummaryItemToJSON = DailySummaryItemToJSON;
