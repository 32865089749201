"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DailySummaryResponseToJSON = exports.DailySummaryResponseFromJSONTyped = exports.DailySummaryResponseFromJSON = void 0;
const _1 = require("./");
function DailySummaryResponseFromJSON(json) {
    return DailySummaryResponseFromJSONTyped(json, false);
}
exports.DailySummaryResponseFromJSON = DailySummaryResponseFromJSON;
function DailySummaryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'entries': (json['entries'].map(_1.DailySummaryItemFromJSON)),
        'total': json['total'],
    };
}
exports.DailySummaryResponseFromJSONTyped = DailySummaryResponseFromJSONTyped;
function DailySummaryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'entries': (value.entries.map(_1.DailySummaryItemToJSON)),
        'total': value.total,
    };
}
exports.DailySummaryResponseToJSON = DailySummaryResponseToJSON;
