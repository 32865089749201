import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Asset, AssetApi} from "client";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	asset: Asset;
	onClose(madeChange: boolean): void;
}

const toggleAssetVisibilityModal: React.FC<IProps> = (props: IProps) => {

	const {token, isOpen, asset} = props;

	function closeHelper(): void {
		props.onClose(false);
	}

	async function toggleAssetVisibility(): Promise<void> {
		props.dispatch(incrementLoading());
		try {
			if (props.asset?.hidden) {
				await new AssetApi(getConfig(token)).showAsset({
					showAssetBody: {
						assetID: asset?._id,
					},
				});
			} else {
				await new AssetApi(getConfig(token)).hideAsset({
					hideAssetBody: {
						assetID: asset?._id,
					},
				});
			}

			props.onClose(true);
		} catch (e) {
			props.dispatch(addError(e));
		}
		props.dispatch(decrementLoading());
	}

	return (
		<Modal
			isOpen={isOpen}
			centered={true}
			toggle={closeHelper}
		>
			<ModalHeader toggle={closeHelper}>
				{props.asset?.hidden ? (
					<React.Fragment>
						Make visible: {asset.name}
					</React.Fragment>
				) : (
					<React.Fragment>
						Hiding: {asset.name}
					</React.Fragment>
				)}
			</ModalHeader>

			<img
				src={asset.url}
				style={{maxWidth: "100%"}}
			/>

			<ModalBody>
				{props.asset?.hidden ? (
					<p>
						{`Are you sure you want to make '${asset.name}' visible again?`}
					</p>
				) : (
					<p>
						{`Are you sure you want to hide '${asset.name}'?`}
					</p>
				)}
			</ModalBody>

			<ModalFooter>
				<Button color="link" className="mr-3" onClick={closeHelper}>
					Cancel
				</Button>
				<Button color={props.asset?.hidden ? "success" : "danger"} onClick={toggleAssetVisibility}>
					{props.asset?.hidden ? "Make visible" : "Hide"}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(toggleAssetVisibilityModal);
