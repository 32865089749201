import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	CompostTempPerPile,
	FieldWork,
	FieldWorkCategory,
	FormEntriesResponse,
	FormIdentifier,
	FormsApi,
	FormType,
	SprayAppDetails,
	SprayAppFields
} from "client";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Bees from "./forms/Bees";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import HarvestRecord from "./forms/HarvestRecord";
import Irrigation from "./forms/Irrigation";
import FieldWorkForm from "./forms/FieldWork";
import CompostTemperatureForm from "./forms/CompostTemperatureForm";
import SprayApplicationForm from "./forms/SprayApplicationForm";
import GenericSuccessModal from "./GenericSuccessModal";
import NotesForm from "./forms/NotesForm";

interface IProps {
	token?: string;
	dispatch?: any;
	submission: FormEntriesResponse;

	onCancel(): void;

	onDone(): void;
}

const FormEditingModal: React.FC<IProps> = (props: IProps) => {

	const {token, submission} = props;
	const [form, setForm] = useState<FormType>(null);
	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		if (submission) {
			setForm({
				...cloneDeep(submission.formType),
			});
		}
	}, [JSON.stringify(submission)]);

	function createOnChange(key: keyof FormType, value: any): void {
		setForm({
			...form,
			[key]: value,
		});
	}

	async function submitUpdate(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			let res;

			switch (submission.formType.formIdentifier) {
				case FormIdentifier.BEES:
					res = await new FormsApi(getConfig(token)).userFormsBeesPut({
						bees: {
							...submission.formType,
							...form,
						},
					});
					break;
				case FormIdentifier.HARVESTRECORD:
					res = await new FormsApi(getConfig(token)).userFormsHarvestRecordPut({
						harvestRecord: {
							...submission.formType,
							...form,
						},
					});
					break;
				case FormIdentifier.IRRIGATION:
					res = await new FormsApi(getConfig(token)).userFormsIrrigationPut({
						irrigation: {
							...submission.formType,
							...form,
						},
					});
					break;
				case FormIdentifier.FIELDWORK:
					const formObject: Partial<FieldWork> = {
						_id: form._id,
						owner: form.owner,
						creationTime: form.creationTime,
						lastTouched: form.lastTouched,
						formIdentifier: form.formIdentifier,
						startTime: form.startTime,
						endTime: form.endTime,
						// field: form.field,
						equipment: form.equipment,
						implement: form.implement,
						category: form.category,
						notes: form.notes,
						checkedEquipment: form.checkedEquipment,
					};

					if (form.category !== FieldWorkCategory.SHOPWORK) {
						formObject.field = form.field;
					}

					if (form.category !== FieldWorkCategory.COMPOSTTURN && form.category !== FieldWorkCategory.SHOPWORK) {
						formObject.crop = form.crop;
					}

					if (form.category === FieldWorkCategory.COMPOSTSPREAD) {
						formObject.compostPile = form.compostPile;
						formObject.applicationRate = parseInt(form.applicationRate as any);
					} else if (form.category === FieldWorkCategory.COMPOSTTURN) {
						formObject.compostPile = form.compostPile;
					} else if (form.category === FieldWorkCategory.CROPMAINTENANCE) {
						formObject.passesOver = parseInt(form.passesOver as any);
					} else if (form.category === FieldWorkCategory.FALLTILLAGE) {
						formObject.passesOver = parseInt(form.passesOver as any);
					} else if (form.category === FieldWorkCategory.FERTILIZE) {
						formObject.fertilizeInput = form.fertilizeInput;
						formObject.applicationRate = parseInt(form.applicationRate as any);
					} else if (form.category === FieldWorkCategory.PLANTING) {
						formObject.seed = form.seed;
						formObject.unit = form.unit;
						formObject.ratePerAcre = parseInt(form.ratePerAcre as any);
						formObject.lotNumber = form.lotNumber;
						formObject.acres = parseInt(form.acres as any);
					} else if (
						form.category === FieldWorkCategory.SPRINGTILLAGE ||
						form.category === FieldWorkCategory.CUSTOMWORK ||
						form.category === FieldWorkCategory.MISCWORK ||
						form.category === FieldWorkCategory.CRANBERRYBOGMAINTENANCE
					) {
						formObject.passesOver = form.passesOver;
					} else if (form.category === FieldWorkCategory.LOADORHAUL) {
						formObject.loadCount = form.loadCount;
						formObject.commodity = form.commodity;
					}

					res = await new FormsApi(getConfig(token)).userFormsFieldWorkPut({
						fieldWork: formObject as FieldWork,
					});
					break;
				case FormIdentifier.COMPOSTTEMPERATURE:
					const piles: Array<CompostTempPerPile> = [];
					for (const temp of form.compostPiles) {
						piles.push({
							compostPile: temp.compostPile,
							temp1: parseInt(temp.temp1 as any),
							temp2: parseInt(temp.temp2 as any),
							temp3: parseInt(temp.temp3 as any),
						});
					}
					delete form.compostPiles;
					form.compostPiles = piles;

					res = await new FormsApi(getConfig(token)).userFormsCompostTemperaturePut({
						compostTemperature: form,
					});
					break;
				case FormIdentifier.SPRAYAPP:
					const sprayDetails: Array<SprayAppDetails> = [];
					const sprayFields: Array<SprayAppFields> = [];
					for (const details of form.sprayDetails) {
						sprayDetails.push({
							sprayProduct: details.sprayProduct,
							units: details.units,
							ratePerAcre: parseInt(details.ratePerAcre as any),
						});
					}

					for (const fields of form.sprayFields) {
						sprayFields.push({
							field: fields.field,
							acres: parseInt(fields.acres as any),
						});
					}
					delete form.sprayDetails;
					delete form.sprayFields;
					form.sprayDetails = sprayDetails;
					form.sprayFields = sprayFields;

					res = await new FormsApi(getConfig(token)).userFormsSprayApplicationPut({
						sprayApp: form,
					});
					break;
				case FormIdentifier.NOTES:
					res = await new FormsApi(getConfig(token)).userFormsNotesPut({
						notes: {
							...submission.formType,
							...form,
						},
					});
					break;
			}

			setShowSuccess(true);
		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function handleOnDone(): void {
		setShowSuccess(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<GenericSuccessModal
				isOpen={showSuccess}
				callback={handleOnDone}
				body="Submission updated successfully."
			/>

			<Modal
				isOpen={submission && Object.keys(submission).length > 0}
				fade={true}
				centered={true}
				contentClassName="px-3"
				toggle={props.onCancel}
			>
				{(submission && Object.keys(submission).length > 0) && (
					<React.Fragment>
						<ModalHeader toggle={props.onCancel}>Update Submission</ModalHeader>

						<ModalBody>
							{submission.formType.formIdentifier === FormIdentifier.BEES && (
								<Bees values={form} onChange={createOnChange}/>
							)}

							{submission.formType.formIdentifier === FormIdentifier.HARVESTRECORD && (
								<HarvestRecord values={form} onChange={createOnChange}/>
							)}

							{submission.formType.formIdentifier === FormIdentifier.IRRIGATION && (
								<Irrigation values={form} onChange={createOnChange}/>
							)}

							{submission.formType.formIdentifier === FormIdentifier.FIELDWORK && (
								<FieldWorkForm values={form} onChange={createOnChange}/>
							)}

							{submission.formType.formIdentifier === FormIdentifier.COMPOSTTEMPERATURE && (
								<CompostTemperatureForm values={form} onChange={createOnChange}/>
							)}

							{submission.formType.formIdentifier === FormIdentifier.SPRAYAPP && (
								<SprayApplicationForm values={form} onChange={createOnChange}/>
							)}

							{submission.formType.formIdentifier === FormIdentifier.NOTES && (
								<NotesForm values={form} onChange={createOnChange}/>
							)}
						</ModalBody>

						<ModalFooter>
							<Button color="link" onClick={props.onCancel}>
								Cancel
							</Button>
							<Button color="primary" onClick={submitUpdate}>
								Save
							</Button>
						</ModalFooter>
					</React.Fragment>
				)}
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(FormEditingModal);
