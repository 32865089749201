import React, {ChangeEventHandler, ReactNode, useEffect, useState} from "react";
import {Bees, CompostTemperature, CompostTempPerPile, DefaultApi, DropdownLabel} from "client";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {connect} from "react-redux";
import DateTimePicker from "./DateTimePicker";
import {momentStringDownToMinutes} from "../../utils/formatters";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import NumberFormat from "react-number-format";
import cloneDeep from "lodash/cloneDeep";

interface IProps {
	token?: string;
	dispatch?: any;
	values: CompostTemperature;

	onChange(key: keyof CompostTemperature, value: any);
}

const CompostTemperatureForm: React.FC<IProps> = (props: IProps) => {

	const {token, values} = props;
	const [compostPiles, setCompostPiles] = useState<string[]>([]);

	useEffect(() => {
		getDropDownValues().then().catch();
	}, []);

	async function getDropDownValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const compostPilesRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.CompostPile}});
			setCompostPiles(compostPilesRes);

		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function dateOnChangeHelper(key: keyof CompostTemperature): (e) => void {
		return (e) => {
			props.onChange(key, e);
		};
	}

	function onChangeHelper(key: keyof CompostTemperature): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			// @ts-ignore
			props.onChange(key, e.target.value);
		};
	}

	function onChangeHelper2(key: keyof CompostTempPerPile, value: string, index: number): void {
		const newTemps: Array<CompostTempPerPile> = cloneDeep(values.compostPiles);
		// @ts-ignore
		newTemps[index][key] = value;
		props.onChange("compostPiles", newTemps);
	}

	function addCompostPile(): void {
		const newTemps: Array<CompostTempPerPile> = cloneDeep(values.compostPiles);
		newTemps.push({
			compostPile: "",
			temp1: undefined,
			temp2: undefined,
			temp3: undefined,
		});
		props.onChange("compostPiles", newTemps);
	}

	function removeCompostPile(): void {
		const newTemps: Array<CompostTempPerPile> = cloneDeep(values.compostPiles);
		newTemps.splice(newTemps.length - 1, 1);
		props.onChange("compostPiles", newTemps);
	}

	function makeDynamicEntries(temps: Array<CompostTempPerPile> = []): ReactNode {
		return temps.map((temp: CompostTempPerPile, i: number) => {

			function onPileChangeHelper(e): void {
				onChangeHelper2("compostPile", e.target.value, i);
			}

			function onTempChangeHelper(key: keyof CompostTempPerPile): (e) => void {
				return (e) => {
					onChangeHelper2(key, e.floatValue, i);
				};
			}

			return (
				<div>
					{i > 0 && (
						<hr className="my-2"/>
					)}

					<div className="mb-2">
						<Label>Compost Pile</Label>
						<Input type="select" value={temp.compostPile} onChange={onPileChangeHelper}>
							<option value="">Make a selection...</option>
							<hr/>
							{compostPiles.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>
					<Row className="mb-2">
						<Col xs={4}>
							<Label>Temp. 1</Label>
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Temperature 1"
								value={temp.temp1}
								customInput={Input}
								thousandSeparator={true}
								onValueChange={onTempChangeHelper("temp1")}
								allowNegative={false}
							/>
						</Col>
						<Col xs={4}>
							<Label>Temp. 2</Label>
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Temperature 2"
								value={temp.temp2}
								customInput={Input}
								thousandSeparator={true}
								onValueChange={onTempChangeHelper("temp2")}
								allowNegative={false}
							/>
						</Col>
						<Col xs={4}>
							<Label>Temp. 3</Label>
							<NumberFormat
								allowLeadingZeros={false}
								placeholder="Temperature 3"
								value={temp.temp3}
								customInput={Input}
								thousandSeparator={true}
								onValueChange={onTempChangeHelper("temp3")}
								allowNegative={false}
							/>
						</Col>
					</Row>
				</div>
			);
		});
	}

	return (
		<div>
			<div className="mb-3">
				<Label>Date</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.date)}
				</p>
				<DateTimePicker time={values.date} onChange={dateOnChangeHelper("date")}/>
			</div>

			<div className="mb-3">
				<Label>Compost Pile Temperatures</Label>
				{makeDynamicEntries(values.compostPiles)}
			</div>

			<div className="d-flex justify-content-between my-3">
				<Button color="info" className="px-4 text-light" onClick={addCompostPile}>
					+
				</Button>

				<Button color="info" className="px-4 text-light" onClick={removeCompostPile}>
					-
				</Button>
			</div>

			<div className="mb-3">
				<Label>Notes</Label>
				<Input type="textarea" value={values.notes} placeholder="Notes" onChange={onChangeHelper("notes")}/>
			</div>

		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(CompostTemperatureForm);
