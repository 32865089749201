"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SprayAppDetailsToJSON = exports.SprayAppDetailsFromJSONTyped = exports.SprayAppDetailsFromJSON = void 0;
const runtime_1 = require("../runtime");
function SprayAppDetailsFromJSON(json) {
    return SprayAppDetailsFromJSONTyped(json, false);
}
exports.SprayAppDetailsFromJSON = SprayAppDetailsFromJSON;
function SprayAppDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sprayProduct': !runtime_1.exists(json, 'sprayProduct') ? undefined : json['sprayProduct'],
        'units': !runtime_1.exists(json, 'units') ? undefined : json['units'],
        'ratePerAcre': !runtime_1.exists(json, 'ratePerAcre') ? undefined : json['ratePerAcre'],
    };
}
exports.SprayAppDetailsFromJSONTyped = SprayAppDetailsFromJSONTyped;
function SprayAppDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sprayProduct': value.sprayProduct,
        'units': value.units,
        'ratePerAcre': value.ratePerAcre,
    };
}
exports.SprayAppDetailsToJSON = SprayAppDetailsToJSON;
