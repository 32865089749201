"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomCSVToJSON = exports.CustomCSVFromJSONTyped = exports.CustomCSVFromJSON = void 0;
const runtime_1 = require("../runtime");
function CustomCSVFromJSON(json) {
    return CustomCSVFromJSONTyped(json, false);
}
exports.CustomCSVFromJSON = CustomCSVFromJSON;
function CustomCSVFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'data': !runtime_1.exists(json, 'data') ? undefined : json['data'],
        'name': json['name'],
        'lastUpdated': !runtime_1.exists(json, 'lastUpdated') ? undefined : json['lastUpdated'],
        'created': !runtime_1.exists(json, 'created') ? undefined : json['created'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
    };
}
exports.CustomCSVFromJSONTyped = CustomCSVFromJSONTyped;
function CustomCSVToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'data': value.data,
        'name': value.name,
        'lastUpdated': value.lastUpdated,
        'created': value.created,
        'description': value.description,
    };
}
exports.CustomCSVToJSON = CustomCSVToJSON;
