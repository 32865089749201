"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownLabelToJSON = exports.DropdownLabelFromJSONTyped = exports.DropdownLabelFromJSON = exports.DropdownLabel = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var DropdownLabel;
(function (DropdownLabel) {
    DropdownLabel["Implement"] = "Implement";
    DropdownLabel["Chemical"] = "Chemical";
    DropdownLabel["Equipment"] = "Equipment";
    DropdownLabel["Field"] = "Field";
    DropdownLabel["Seed"] = "Seed";
    DropdownLabel["BeeActivity"] = "BeeActivity";
    DropdownLabel["Crop"] = "Crop";
    DropdownLabel["Truck"] = "Truck";
    DropdownLabel["Units"] = "Units";
    DropdownLabel["Destination"] = "Destination";
    DropdownLabel["CompostPile"] = "CompostPile";
    DropdownLabel["Fertilizer"] = "Fertilizer";
    DropdownLabel["Weather"] = "Weather";
    DropdownLabel["SprayProduct"] = "SprayProduct";
    DropdownLabel["Commodity"] = "Commodity";
})(DropdownLabel = exports.DropdownLabel || (exports.DropdownLabel = {}));
function DropdownLabelFromJSON(json) {
    return DropdownLabelFromJSONTyped(json, false);
}
exports.DropdownLabelFromJSON = DropdownLabelFromJSON;
function DropdownLabelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DropdownLabelFromJSONTyped = DropdownLabelFromJSONTyped;
function DropdownLabelToJSON(value) {
    return value;
}
exports.DropdownLabelToJSON = DropdownLabelToJSON;
