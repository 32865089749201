import {ButtonGroup, Input, ListGroup, ListGroupItem, Button, Form} from "reactstrap";
import React, {ChangeEventHandler, useState} from "react";

interface IProps {
	values: string[];
	onChange: (v: string[]) => void;
	onSubmit: () => void;
}

const DropdownEditor: React.FC<IProps> = (props) => {

	const [search, setSearch] = useState("");
	const [newEntry, setNewEntry] = useState("");

	function createEntry(item: string, i: number, arr: string[]) {

		if (search && search.length > 0 && !item.includes(search)) {
			return null;
		}

		function moveUp() {
			const copy = [...props.values];
			copy[i - 1] = props.values[i];
			copy[i] = props.values[i - 1];
			props.onChange(copy);
		}

		function moveDown() {
			const copy = [...props.values];
			copy[i + 1] = props.values[i];
			copy[i] = props.values[i + 1];
			props.onChange(copy);
		}

		function deleteEntry() {
			const copy = [...props.values];
			copy.splice(i, 1);
			props.onChange(copy);
		}

		const onTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
			const copy = [...props.values];
			copy[i] = e.target.value;
			props.onChange(copy);
		}


		return (
			<ListGroupItem className="d-flex justify-content-between align-items-center" key={i}>
				<b>{i}</b>
				<Input className="ml-4" value={item} onChange={onTextChange}/>
				<ButtonGroup className="ml-4">
					<Button className="text-nowrap" color="primary" disabled={i === 0} onClick={moveUp}>Move Up</Button>
					<Button className="text-nowrap" color="primary" disabled={i === arr.length - 1} onClick={moveDown}>Move Down</Button>
					<Button className="text-nowrap" color="danger" onClick={deleteEntry}>Delete</Button>
				</ButtonGroup>
			</ListGroupItem>
		)
	}

	function addNewOption() {
		props.onChange([...props.values, newEntry])
		setNewEntry("");
	}

	const onNewEntryChange: ChangeEventHandler<HTMLInputElement> = e => {
		setNewEntry(e.target.value);
	}

	const onSearchChange: ChangeEventHandler<HTMLInputElement> = e => {
		setSearch(e.target.value);
	}

	const clearSearch = () => setSearch("");

	return (
		<ListGroup>
			<ListGroupItem  className="d-flex justify-content-between">
				<Input type="text" value={search} placeholder="Type to search." onChange={onSearchChange}/>
				<Button className="mt-1 text-nowrap ml-4" color="danger" onClick={clearSearch} disabled={!search || search.length < 1}>Clear Search</Button>
			</ListGroupItem>
			{props.values.map(createEntry)}
			<ListGroupItem className="d-flex justify-content-between">
				<Input type="text" placeholder="Enter new Option Value." value={newEntry} onChange={onNewEntryChange}/>
				<Button className="mt-1 text-nowrap ml-4" color="primary" onClick={addNewOption} disabled={!newEntry || newEntry.length < 1}>Add New Option</Button>
			</ListGroupItem>
			<ListGroupItem className="d-flex justify-content-center">
				<Button color="primary" onClick={props.onSubmit}>Save Changes</Button>
			</ListGroupItem>
		</ListGroup>
	)
};

export default DropdownEditor;