import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Bees, DefaultApi, DropdownLabel} from "client";
import {Input, Label} from "reactstrap";
import {connect} from "react-redux";
import DateTimePicker from "./DateTimePicker";
import {momentStringDownToMinutes} from "../../utils/formatters";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";

interface IProps {
	token?: string;
	dispatch?: any;
	values: Bees;

	onChange(key: keyof Bees, value: any);
}

const BeesForm: React.FC<IProps> = (props: IProps) => {

	const {token, values} = props;
	const [options, setOptions] = useState<string[]>([]);

	useEffect(() => {
		getDropDownValues().then().catch();
	}, []);

	async function getDropDownValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.BeeActivity}});

			setOptions(res);
		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function dateOnChangeHelper(key: keyof Bees): (e) => void {
		return (e) => {
			props.onChange(key, e);
		};
	}

	function onChangeHelper(key: keyof Bees): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			// @ts-ignore
			props.onChange(key, e.target.value);
		};
	}

	return (
		<div>
			<div className="mb-3">
				<Label>Start Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.startTime)}
				</p>
				<DateTimePicker time={values.startTime} onChange={dateOnChangeHelper("startTime")}/>
			</div>

			<div className="mb-3">
				<Label>End Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.endTime)}
				</p>
				<DateTimePicker time={values.endTime} onChange={dateOnChangeHelper("endTime")}/>
			</div>

			<div className="mb-3">
				<Label>Activity</Label>
				<Input type="select" value={values.activity} onChange={onChangeHelper("activity")}>
					<option value="">Make a selection...</option>
					<hr/>
					{options.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Notes</Label>
				<Input type="textarea" value={values.notes} placeholder="Notes" onChange={onChangeHelper("notes")}/>
			</div>

		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(BeesForm);
