"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HarvestRecordToJSON = exports.HarvestRecordFromJSONTyped = exports.HarvestRecordFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function HarvestRecordFromJSON(json) {
    return HarvestRecordFromJSONTyped(json, false);
}
exports.HarvestRecordFromJSON = HarvestRecordFromJSON;
function HarvestRecordFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.AbstractFormFromJSONTyped(json, ignoreDiscriminator),
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'startTime': json['startTime'],
        'field': !runtime_1.exists(json, 'field') ? undefined : json['field'],
        'crop': !runtime_1.exists(json, 'crop') ? undefined : json['crop'],
        'truck': !runtime_1.exists(json, 'truck') ? undefined : json['truck'],
        'quantity': !runtime_1.exists(json, 'quantity') ? undefined : json['quantity'],
        'units': !runtime_1.exists(json, 'units') ? undefined : json['units'],
        'loadTime': !runtime_1.exists(json, 'loadTime') ? undefined : json['loadTime'],
        'destination': !runtime_1.exists(json, 'destination') ? undefined : json['destination'],
    };
}
exports.HarvestRecordFromJSONTyped = HarvestRecordFromJSONTyped;
function HarvestRecordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.AbstractFormToJSON(value),
        '_id': value._id,
        'startTime': value.startTime,
        'field': value.field,
        'crop': value.crop,
        'truck': value.truck,
        'quantity': value.quantity,
        'units': value.units,
        'loadTime': value.loadTime,
        'destination': value.destination,
    };
}
exports.HarvestRecordToJSON = HarvestRecordToJSON;
