import React, {ReactEventHandler, ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import AssetListItem from "./AssetListItem";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Asset, AssetApi} from "client";
import getConfig from "../../utils/getConfig";
import AddAssetModal from "./AddAssetModal";

interface IProps {
	token?: string;
	dispatch?: any;
	isOpen: boolean;
	allowSelect: boolean;

	onClose(e?): void;
	onSelect?(asset: Asset): void;
}

const AssetManager: React.FC<IProps> = (props: IProps) => {

	const {token, isOpen, allowSelect} = props;
	const [assets, setAssets] = useState<Array<Asset>>();
	const [showAdd, setShowAdd] = useState(false);
	const [showHidden, setShowHidden] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [listRenderKey, setListRenderKey] = useState(1);

	useEffect(() => {
		if (isOpen) {
			readAssets().then().catch();
		}
	}, [isOpen, showHidden]);

	useEffect(() => {
		setListRenderKey(listRenderKey + 1);
	}, [JSON.stringify(assets)]);

	function toggleAddModal(getNewData: boolean): void {
		setShowAdd(!showAdd);
		if (getNewData) {
			readAssets().then().catch();
		}
	}

	function toggleShowHidden(): void {
		setShowHidden(!showHidden);
	}

	function onFilterChanger(e: React.ChangeEvent<HTMLInputElement>): void {
		setSearchValue(e?.target?.value);
		setListRenderKey(listRenderKey + 1);
	}

	async function readAssets(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new AssetApi(getConfig(token)).getAssetManagerAssets({
				showHidden,
			});
			setAssets(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());

	}

	function makeAssetList(_assets: Array<Asset> = []): ReactNode {
		return _assets.map((_asset: Asset, i: number) => {

			function onSelectHelper(): void {
				props.onSelect(_asset);
			}

			return (
				<div>
					{i > 0 && (
						<hr/>
					)}
					<AssetListItem
						key={`asset-list-item_${i}`}
						asset={_asset}
						allowSelect={allowSelect}
						onFinishedChanges={readAssets}
						onSelect={onSelectHelper}
					/>
				</div>
			);
		});
	}

	function findFilteredAssets(_assets: Array<Asset>, _filter: string = searchValue): Array<Asset> {
		return _assets.filter((_a: Asset) => {
			return _a?.name?.toLowerCase()?.includes(_filter?.toLowerCase()) || _a?.description?.toLowerCase()?.includes(_filter?.toLowerCase())
		})
	}

	return (
		<React.Fragment>
			<AddAssetModal
				isOpen={showAdd}
				onClose={toggleAddModal}
			/>

			<Modal
				isOpen={isOpen}
				centered={true}
				toggle={props.onClose}
				style={{maxHeight: "90vh"}}
			>
				<ModalHeader toggle={props.onClose}>Asset Manager</ModalHeader>

				<ModalBody style={{maxHeight: "80vh", overflowY: "scroll"}}>
					{assets ? (
						<React.Fragment>
							<div className="mb-2">
								<input
									type="checkbox"
									name="showHiddenAssetsCheckbox"
									id="showHiddenAssetsCheckbox"
									checked={showHidden}
									onChange={toggleShowHidden}
								/>
								<label htmlFor="showHiddenAssetsCheckbox">View Hidden Assets</label>
							</div>

							<div className="mb-4">
								<Label>Filter Asset List</Label>
								<Input
									placeholder="Filter by name or description..."
									value={searchValue}
									onChange={onFilterChanger}
								/>
							</div>

							{assets.length < 1 ? (
								<div>
									<p className="text-center mb-0">
										{showHidden ? (
											`No hidden assets.`
										) : (
											`No assets uploaded yet. Click the "Add Asset" button below to add one.`
										)}
									</p>
								</div>
							) : (
								<div key={listRenderKey}>
									{makeAssetList(findFilteredAssets(assets, searchValue))}
								</div>
							)}
						</React.Fragment>
					) : (
						<div className="m-4 d-flex justify-content-center">
							<div className="d-flex align-items-center">
								<Spinner size="sm" className="mr-3"/>
								Loading Assets...
							</div>
						</div>
					)}
				</ModalBody>

				<ModalFooter>
					<Button color="link" onClick={props.onClose} className="mr-3">
						Close
					</Button>

					{!showHidden && (
						<Button color="primary" onClick={() => {setShowAdd(true)}}>
							Add Asset
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token,
	}
})(AssetManager);
