"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSVIDToJSON = exports.CSVIDFromJSONTyped = exports.CSVIDFromJSON = exports.CSVID = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var CSVID;
(function (CSVID) {
    CSVID["INDEX"] = "MAP_INDEX";
    CSVID["ADDRESSES"] = "MAP_ADDRESSES";
})(CSVID = exports.CSVID || (exports.CSVID = {}));
function CSVIDFromJSON(json) {
    return CSVIDFromJSONTyped(json, false);
}
exports.CSVIDFromJSON = CSVIDFromJSON;
function CSVIDFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.CSVIDFromJSONTyped = CSVIDFromJSONTyped;
function CSVIDToJSON(value) {
    return value;
}
exports.CSVIDToJSON = CSVIDToJSON;
