import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Container, Spinner} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {AdminApi, Asset, CSVID, DefaultApi} from "client";
import getConfig from "../utils/getConfig";
import MapInput from "../components/MapInput";
import CSVViewer from "../components/CSVViewer";
import CSVInputModal from "../components/CSVInputModal";
import AssetManager from "../components/assetManager/AssetManager";

interface IProps {
	token: string;
	dispatch: any;
}

const MapManagement: React.FC<IProps> = (props) => {

	const [maps, setMaps] = useState<Asset[]>();
	const [assetManagerOpen, setAssetManagerOpen] = useState(false);
	const [addressesViewOpen, setAddressesViewOpen] = useState(false);
	const [addressesEditViewOpen, setAddressesEditViewOpen] = useState(false);
	const [indexesViewOpen, setIndexesViewOpen] = useState(false);
	const [indexesEditViewOpen, setIndexesEditViewOpen] = useState(false);

	useEffect(() => {
		getMaps().then().catch();
	}, [])

	async function getMaps() {
		props.dispatch(incrementLoading());
		try {
			const res = await new DefaultApi(getConfig(props.token)).mapListGet();
			setMaps(res);
		} catch (err) {
			props.dispatch(addError(err))
		}
		props.dispatch(decrementLoading());
	}

	async function updateMaps() {
		props.dispatch(incrementLoading());
		try {
			await new AdminApi(getConfig(props.token)).updateMapPut({asset: maps});
			await getMaps();
		} catch (err) {
			props.dispatch(addError(err))
		}
		props.dispatch(decrementLoading());
	}

	function onAssetSelect(asset: Asset) {
		closeAssetManager();
		setMaps([...maps, asset]);
	}

	const closeAssetManager = () => setAssetManagerOpen(false);
	const addNewImage = () => setAssetManagerOpen(true);
	const closeAddressesView = () => setAddressesViewOpen(false);
	const openAddressesView = () => setAddressesViewOpen(true);
	const closeIndexView = () => setIndexesViewOpen(false);
	const openIndexView = () => setIndexesViewOpen(true);
	const closeAddressesEditView = () => setAddressesEditViewOpen(false);
	const openAddressesEditView = () => setAddressesEditViewOpen(true);
	const closeIndexEditView = () => setIndexesEditViewOpen(false);
	const openIndexEditView = () => setIndexesEditViewOpen(true);

	return (
		<React.Fragment>
			<AssetManager isOpen={assetManagerOpen} onClose={closeAssetManager} onSelect={onAssetSelect} allowSelect={true}/>
			<CSVViewer csvID={CSVID.ADDRESSES} isOpen={addressesViewOpen} close={closeAddressesView}/>
			<CSVViewer csvID={CSVID.INDEX} isOpen={indexesViewOpen} close={closeIndexView}/>
			<CSVInputModal csvID={CSVID.ADDRESSES} open={addressesEditViewOpen} close={closeAddressesEditView}/>
			<CSVInputModal csvID={CSVID.INDEX} open={indexesEditViewOpen} close={closeIndexEditView}/>

			<Container className="my-4">
				<div className="mb-4">
					<h1>
						Maps
					</h1>
					<p>
						This page is for managing the map images inside the application as well as the indexes.
					</p>
					<ButtonGroup>
						<Button color="primary" onClick={openAddressesView}>
							View Addresses
						</Button>
						<Button color="primary" onClick={openAddressesEditView}>
							Edit Addresses
						</Button>
						<Button color="primary" onClick={openIndexView}>
							View Indexes
						</Button>
						<Button color="primary" onClick={openIndexEditView}>
							Edit Indexes
						</Button>
						<Button color="primary" disabled={assetManagerOpen || !maps} onClick={addNewImage}>
							Add New Map Image
						</Button>
					</ButtonGroup>
				</div>

				{
					maps ?
						<MapInput value={maps} onChange={setMaps}/> :
						<Spinner className="align-self-center"/>
				}

				<div className="mt-4">
					<Button color="primary" disabled={!maps} onClick={updateMaps}>
						Update Maps
					</Button>
				</div>
			</Container>
		</React.Fragment>
	)
}

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	}
})(MapManagement);

