import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ErrorManager from "./components/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import Login from "./pages/Login";
import NotFoundPage from "./pages/NotFoundPage";
import Dashboard from "./pages/Dashboard";
import ManageAdmins from "./pages/ManageAdmins";
import ManageUsers from "./pages/ManageUsers";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import CustomDropdownsManager from "./pages/CustomDropdownsManager";
import FormSubmissionsPage from "./pages/FormSubmissionsPage";
import CustomCSVManager from "./pages/CustomCSVManager";
import MapManagement from "./pages/MapManagement";
import SideBarManager from "./components/sideBar/SideBarManager";

const App: React.FC = () => {

	return (
		<BrowserRouter>

			<ErrorManager/>
			<LoadingManager/>
			<TokenManager/>

			<SideBarManager>
				<main className="mh-100">
					<Switch>
						<Route exact path="/" component={Login}/>
						<AuthenticatedRoute exact path="/dashboard" component={Dashboard}/>
						<AuthenticatedRoute exact path="/manage-admins" component={ManageAdmins}/>
						<AuthenticatedRoute exact path="/manage-users" component={ManageUsers}/>
						<AuthenticatedRoute exact path="/custom-dropdown-manager" component={CustomDropdownsManager}/>
						<AuthenticatedRoute exact path="/custom-csv-manager" component={CustomCSVManager}/>
						<AuthenticatedRoute exact path="/form-submission" component={FormSubmissionsPage}/>
						<AuthenticatedRoute exact path="/map-manager" component={MapManagement}/>
						<Route exact path="/404" component={NotFoundPage}/>
						<Redirect to="/404"/>
					</Switch>
				</main>
			</SideBarManager>
		</BrowserRouter>
	);
};

export default App;
