"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineObject3ToJSON = exports.InlineObject3FromJSONTyped = exports.InlineObject3FromJSON = void 0;
const runtime_1 = require("../runtime");
function InlineObject3FromJSON(json) {
    return InlineObject3FromJSONTyped(json, false);
}
exports.InlineObject3FromJSON = InlineObject3FromJSON;
function InlineObject3FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'csv': json['csv'],
    };
}
exports.InlineObject3FromJSONTyped = InlineObject3FromJSONTyped;
function InlineObject3ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'csv': value.csv,
    };
}
exports.InlineObject3ToJSON = InlineObject3ToJSON;
