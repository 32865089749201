import React, {ChangeEventHandler, useEffect, useState} from "react";
import {DefaultApi, DropdownLabel, FieldWork, FieldWorkCategory} from "client";
import {Input, Label} from "reactstrap";
import {connect} from "react-redux";
import DateTimePicker from "./DateTimePicker";
import {momentStringDownToMinutes} from "../../utils/formatters";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {generateFieldCategoryDropDownList, ILabelValuePair} from "../../utils/fieldCategoryLookup";
import NumberFormat from "react-number-format";

interface IProps {
	token?: string;
	dispatch?: any;
	values: FieldWork;

	onChange(key: keyof FieldWork, value: any);
}

const FieldWorkForm: React.FC<IProps> = (props: IProps) => {

	const {token, values} = props;
	const [fields, setFields] = useState<string[]>([]);
	const [equipment, setEquipment] = useState<string[]>([]);
	const [implement, setImplement] = useState<string[]>([]);
	const [crops, setCrops] = useState<string[]>([]);
	const [compostPiles, setCompostPiles] = useState<string[]>([]);
	const [fertilizeInputs, setFertilizeInputs] = useState<string[]>([]);
	const [seeds, setSeeds] = useState<string[]>([]);
	const [units, setUnits] = useState<string[]>([]);
	const [commodities, setCommodities] = useState<string[]>([]);

	useEffect(() => {
		getDropDownValues().then().catch();
	}, []);

	async function getDropDownValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const fieldsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Field}});
			setFields(fieldsRes);

			const equipmentRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Equipment}});
			setEquipment(equipmentRes);

			const implementRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Implement}});
			setImplement(implementRes);

			const cropsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Crop}});
			setCrops(cropsRes);

			const compostPilesRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.CompostPile}});
			setCompostPiles(compostPilesRes);

			const fertilizeInputsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Fertilizer}});
			setFertilizeInputs(fertilizeInputsRes);

			const seedsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Seed}});
			setSeeds(seedsRes);

			const unitsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Units}});
			setUnits(unitsRes);

			const commoditiesRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Commodity}});
			setCommodities(commoditiesRes);

		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function dateOnChangeHelper(key: keyof FieldWork): (e) => void {
		return (e) => {
			props.onChange(key, e);
		};
	}

	function onChangeHelper(key: keyof FieldWork): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			// @ts-ignore
			props.onChange(key, e.target.value);
		};
	}

	function onNumberChange(key: keyof FieldWork): any {
		return (e) => {
			props.onChange(key, e.floatValue);
		}
	}

	function toggleCheckedEquipment(): void {
		props.onChange("checkedEquipment", !values.checkedEquipment);
	}

	return (
		<div>
			<div className="mb-3">
				<Label>Start Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.startTime)}
				</p>
				<DateTimePicker time={values.startTime} onChange={dateOnChangeHelper("startTime")}/>
			</div>

			<div className="mb-3">
				<Label>End Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.endTime)}
				</p>
				<DateTimePicker time={values.endTime} onChange={dateOnChangeHelper("endTime")}/>
			</div>

			{values.category !== FieldWorkCategory.SHOPWORK && (
				<div className="mb-3">
					<Label>Field</Label>
					<Input type="select" value={values.field} onChange={onChangeHelper("field")}>
						<option value="">Make a selection...</option>
						<hr/>
						{fields.map((option) => {
							return (
								<option value={option}>{option}</option>
							);
						})}
					</Input>
				</div>
			)}

			<div className="mb-3">
				<Label>Equipment</Label>
				<Input type="select" value={values.equipment} onChange={onChangeHelper("equipment")}>
					<option value="">Make a selection...</option>
					<hr/>
					{equipment.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Implement</Label>
				<Input type="select" value={values.implement} onChange={onChangeHelper("implement")}>
					<option value="">Make a selection...</option>
					<hr/>
					{implement.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			{(values.category !== FieldWorkCategory.COMPOSTTURN && values.category !== FieldWorkCategory.SHOPWORK) && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Crop</Label>
						<Input type="select" value={values.crop} onChange={onChangeHelper("crop")}>
							<option value="">Make a selection...</option>
							<hr/>
							{crops.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>
				</React.Fragment>
			)}

			<div className="mb-3">
				<Label>Category</Label>
				<Input type="select" value={values.category} onChange={onChangeHelper("category")}>
					<option value="">Make a selection...</option>
					<hr/>
					{generateFieldCategoryDropDownList().map((option: ILabelValuePair) => {
						return (
							<option value={option.value}>{option.label}</option>
						);
					})}
				</Input>
			</div>

			{values.category === FieldWorkCategory.COMPOSTSPREAD && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Compost Pile</Label>
						<Input type="select" value={values.compostPile} onChange={onChangeHelper("compostPile")}>
							<option value="">Make a selection...</option>
							<hr/>
							{compostPiles.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>

					<div className="mb-3">
						<Label>Application Rate</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Application Rate"
							value={values.applicationRate}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onNumberChange("applicationRate")}
							allowNegative={false}
						/>
					</div>
				</React.Fragment>
			)}

			{values.category === FieldWorkCategory.COMPOSTTURN && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Compost Pile</Label>
						<Input type="select" value={values.compostPile} onChange={onChangeHelper("compostPile")}>
							<option value="">Make a selection...</option>
							<hr/>
							{compostPiles.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>
				</React.Fragment>
			)}

			{values.category === FieldWorkCategory.CROPMAINTENANCE && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Passes Over</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Passes Over"
							value={values.passesOver}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onNumberChange("passesOver")}
							allowNegative={false}
						/>
					</div>
				</React.Fragment>
			)}

			{values.category === FieldWorkCategory.FALLTILLAGE && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Passes Over</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Passes Over"
							value={values.passesOver}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onNumberChange("passesOver")}
							allowNegative={false}
						/>
					</div>
				</React.Fragment>
			)}

			{values.category === FieldWorkCategory.FERTILIZE && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Input</Label>
						<Input type="select" value={values.fertilizeInput} onChange={onChangeHelper("fertilizeInput")}>
							<option value="">Make a selection...</option>
							<hr/>
							{fertilizeInputs.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>

					<div className="mb-3">
						<Label>Application Rate</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Application Rate"
							value={values.applicationRate}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onNumberChange("applicationRate")}
							allowNegative={false}
						/>
					</div>
				</React.Fragment>
			)}

			{values.category === FieldWorkCategory.PLANTING && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Seed</Label>
						<Input type="select" value={values.seed} onChange={onChangeHelper("seed")}>
							<option value="">Make a selection...</option>
							<hr/>
							{seeds.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>

					<div className="mb-3">
						<Label>Units</Label>
						<Input type="select" value={values.unit} onChange={onChangeHelper("unit")}>
							<option value="">Make a selection...</option>
							<hr/>
							{units.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>

					<div className="mb-3">
						<Label>Rate Per Acre</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Rate Per Acres"
							value={values.ratePerAcre}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onNumberChange("ratePerAcre")}
							allowNegative={false}
						/>
					</div>

					<div className="mb-3">
						<Label>Lot Number</Label>
						<Input type="text" value={values.lotNumber} placeholder="Lot Number" onChange={onChangeHelper("lotNumber")}/>
					</div>

					<div className="mb-3">
						<Label>Acres</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Number of Acres"
							value={values.acres}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onNumberChange("acres")}
							allowNegative={false}
						/>
					</div>
				</React.Fragment>
			)}

			{(
				values.category === FieldWorkCategory.SPRINGTILLAGE ||
				values.category === FieldWorkCategory.CUSTOMWORK ||
				values.category === FieldWorkCategory.MISCWORK ||
				values.category === FieldWorkCategory.CRANBERRYBOGMAINTENANCE
			) && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Passes Over</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Passes Over"
							value={values.passesOver}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onNumberChange("passesOver")}
							allowNegative={false}
						/>
					</div>
				</React.Fragment>
			)}

			{(values.category === FieldWorkCategory.LOADORHAUL) && (
				<React.Fragment>
					<div className="mb-3">
						<Label>Load Count</Label>
						<NumberFormat
							allowLeadingZeros={false}
							placeholder="Load Count"
							value={values.loadCount}
							customInput={Input}
							thousandSeparator={true}
							onValueChange={onNumberChange("loadCount")}
							allowNegative={false}
						/>
					</div>

					<div className="mb-3">
						<Label>Commodity</Label>
						<Input type="select" value={values.commodity} onChange={onChangeHelper("commodity")}>
							<option value="">Make a selection...</option>
							<hr/>
							{commodities.map((option) => {
								return (
									<option value={option}>{option}</option>
								);
							})}
						</Input>
					</div>
				</React.Fragment>
			)}

			<div className="mb-3">
				<Label>Notes</Label>
				<Input type="textarea" value={values.notes} placeholder="Notes" onChange={onChangeHelper("notes")}/>
			</div>

			<div className="mb-3">
				<p className="text-center">
					<input
						type="checkbox"
						name="checkedEquipmentCheckbox"
						id="checkedEquipmentCheckbox"
						checked={values.checkedEquipment}
						onChange={toggleCheckedEquipment}
					/>
					<label htmlFor="checkedEquipmentCheckbox">Checked Equipment</label>
				</p>
			</div>

		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(FieldWorkForm);
