"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class AdminApi extends runtime.BaseAPI {
    /**
     * gets the data for a particular csv
     */
    async adminCsvIdGetRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling adminCsvIdGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/admin/csv/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CSVFromJSON(jsonValue));
    }
    /**
     * gets the data for a particular csv
     */
    async adminCsvIdGet(requestParameters) {
        const response = await this.adminCsvIdGetRaw(requestParameters);
        return await response.value();
    }
    /**
     * Replace a csv; _id would be CSVID enum
     */
    async adminCsvPutRaw(requestParameters) {
        if (requestParameters.csv === null || requestParameters.csv === undefined) {
            throw new runtime.RequiredError('csv', 'Required parameter requestParameters.csv was null or undefined when calling adminCsvPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.id !== undefined) {
            formParams.append('_id', requestParameters.id);
        }
        if (requestParameters.csv !== undefined) {
            formParams.append('csv', requestParameters.csv);
        }
        const response = await this.request({
            path: `/admin/csv`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Replace a csv; _id would be CSVID enum
     */
    async adminCsvPut(requestParameters) {
        await this.adminCsvPutRaw(requestParameters);
    }
    /**
     * Delete a custom csv. This has destructive properties, if the custom csv is being deleted and it is referenced somewhere, this will make that reference point at null. Make sure to display warning to any Admin using this.
     */
    async adminCustomCsvDeleteRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/custom_csv`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CustomCSVToJSON(requestParameters.customCSV),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a custom csv. This has destructive properties, if the custom csv is being deleted and it is referenced somewhere, this will make that reference point at null. Make sure to display warning to any Admin using this.
     */
    async adminCustomCsvDelete(requestParameters) {
        await this.adminCustomCsvDeleteRaw(requestParameters);
    }
    /**
     * gets the data for a particular custom csv
     */
    async adminCustomCsvIdGetRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling adminCustomCsvIdGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/admin/custom_csv/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.CustomCSVFromJSON(jsonValue));
    }
    /**
     * gets the data for a particular custom csv
     */
    async adminCustomCsvIdGet(requestParameters) {
        const response = await this.adminCustomCsvIdGetRaw(requestParameters);
        return await response.value();
    }
    /**
     * Returns a list of custom csv, excluding data
     */
    async adminCustomCsvListGetRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/admin/custom_csv_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.CustomCSVFromJSON));
    }
    /**
     * Returns a list of custom csv, excluding data
     */
    async adminCustomCsvListGet() {
        const response = await this.adminCustomCsvListGetRaw();
        return await response.value();
    }
    /**
     * Upload a custom csv.
     */
    async adminCustomCsvPostRaw(requestParameters) {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name', 'Required parameter requestParameters.name was null or undefined when calling adminCustomCsvPost.');
        }
        if (requestParameters.csv === null || requestParameters.csv === undefined) {
            throw new runtime.RequiredError('csv', 'Required parameter requestParameters.csv was null or undefined when calling adminCustomCsvPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description);
        }
        if (requestParameters.csv !== undefined) {
            formParams.append('csv', requestParameters.csv);
        }
        const response = await this.request({
            path: `/admin/custom_csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Upload a custom csv.
     */
    async adminCustomCsvPost(requestParameters) {
        await this.adminCustomCsvPostRaw(requestParameters);
    }
    /**
     * Replace a custom csv. This will allow updating the name, description or anything else
     */
    async adminCustomCsvPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.id !== undefined) {
            formParams.append('_id', requestParameters.id);
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description);
        }
        if (requestParameters.csv !== undefined) {
            formParams.append('csv', requestParameters.csv);
        }
        const response = await this.request({
            path: `/admin/custom_csv`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Replace a custom csv. This will allow updating the name, description or anything else
     */
    async adminCustomCsvPut(requestParameters) {
        await this.adminCustomCsvPutRaw(requestParameters);
    }
    /**
     * Get a list of all the form entries and corresponding owner Name.
     */
    async adminFormEntriesGetRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.formIdentifier !== undefined) {
            queryParameters['formIdentifier'] = requestParameters.formIdentifier;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/form_entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.FormEntriesResponseFromJSON));
    }
    /**
     * Get a list of all the form entries and corresponding owner Name.
     */
    async adminFormEntriesGet(requestParameters) {
        const response = await this.adminFormEntriesGetRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to login an admin account. Returns a token if successful
     */
    async adminLoginRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/admin/admin_login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AdminLoginBodyToJSON(requestParameters.adminLoginBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TokenFromJSON(jsonValue));
    }
    /**
     * Used to login an admin account. Returns a token if successful
     */
    async adminLogin(requestParameters) {
        const response = await this.adminLoginRaw(requestParameters);
        return await response.value();
    }
    /**
     * Run all the adapters with the sql.
     */
    async adminRunAdaptersGetRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/run_adapters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Run all the adapters with the sql.
     */
    async adminRunAdaptersGet() {
        await this.adminRunAdaptersGetRaw();
    }
    /**
     * It takes in all the values for a dropdown and perform CRUD on that particular dropdown if exists.
     */
    async adminUpdateDropdownPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/update_dropdown`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.DropdownToJSON(requestParameters.dropdown),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * It takes in all the values for a dropdown and perform CRUD on that particular dropdown if exists.
     */
    async adminUpdateDropdownPut(requestParameters) {
        await this.adminUpdateDropdownPutRaw(requestParameters);
    }
    /**
     * Used by admins to change their passwords. Also used by GOD admins to change other admin passwords.
     */
    async changeAdminPasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/change_admin_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeAdminPasswordBodyToJSON(requestParameters.changeAdminPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to change their passwords. Also used by GOD admins to change other admin passwords.
     */
    async changeAdminPassword(requestParameters) {
        await this.changeAdminPasswordRaw(requestParameters);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/change_user_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeUserPasswordBodyToJSON(requestParameters.changeUserPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPassword(requestParameters) {
        await this.changeUserPasswordRaw(requestParameters);
    }
    /**
     * Used by admins to create new admin accounts
     */
    async createRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateAdminBodyToJSON(requestParameters.createAdminBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to create new admin accounts
     */
    async create(requestParameters) {
        await this.createRaw(requestParameters);
    }
    /**
     * Used by GOD admins to disable/re-enable admin\'s ability to log in
     */
    async disableOrReEnableAdminRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/disable_or_re_enable_admin`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AdminIDBodyToJSON(requestParameters.adminIDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to disable/re-enable admin\'s ability to log in
     */
    async disableOrReEnableAdmin(requestParameters) {
        await this.disableOrReEnableAdminRaw(requestParameters);
    }
    /**
     * Used by GOD admins to disable/re-enable user\'s ability to log in
     */
    async disableOrReEnableUserRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/disable_or_re_enable_user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UserIDBodyToJSON(requestParameters.userIDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to disable/re-enable user\'s ability to log in
     */
    async disableOrReEnableUser(requestParameters) {
        await this.disableOrReEnableUserRaw(requestParameters);
    }
    /**
     */
    async getAdminListRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_admin_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.AdminFromJSON));
    }
    /**
     */
    async getAdminList() {
        const response = await this.getAdminListRaw();
        return await response.value();
    }
    /**
     */
    async getUserListRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_user_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.UserFromJSON));
    }
    /**
     */
    async getUserList() {
        const response = await this.getUserListRaw();
        return await response.value();
    }
    /**
     */
    async profileGetRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AdminFromJSON(jsonValue));
    }
    /**
     */
    async profileGet() {
        const response = await this.profileGetRaw();
        return await response.value();
    }
    /**
     * It takes in all the assets for the Map and perform CRUD on the Map.
     */
    async updateMapPutRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/update_map`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.asset.map(models_1.AssetToJSON),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * It takes in all the assets for the Map and perform CRUD on the Map.
     */
    async updateMapPut(requestParameters) {
        await this.updateMapPutRaw(requestParameters);
    }
}
exports.AdminApi = AdminApi;
