import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Container, Input, Label} from "reactstrap";
import FormSubmissionsTable, {IFormSubmissionsTableRow} from "../components/FormSubmissionsTable";
import {AdminApi, FormEntriesResponse, FormIdentifier} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import cloneDeep from "lodash/cloneDeep";
import FormEditingModal from "../components/FormEditingModal";
import {formIdentifierParser} from "../utils/formIdentifierParser";
import FormDeletingModal from "../components/FormDeletingModal";

interface IProps {
	token?: string;
	dispatch?: any;
}

const FormSubmissionsPage: React.FC<IProps> = (props: IProps) => {

	const {token} = props;
	const [tableFilter, setTableFilter] = useState<FormIdentifier>(FormIdentifier.FIELDWORK);
	const [tableData, setTableData] = useState<Array<IFormSubmissionsTableRow>>([]);
	const [formToEdit, setFormToEdit] = useState<FormEntriesResponse>(null);
	const [formToDelete, setFormToDelete] = useState<FormEntriesResponse>(null);

	useEffect(() => {
		if (tableFilter) {
			getFormSubmissions().then().catch();
		}
	}, [JSON.stringify(tableFilter)]);

	function filterOnChange(e): void {
		const f = e.target.value;
		props.dispatch(incrementLoading());
		setTableFilter(null);
		setTimeout(() => {
			setTableFilter(f);
			props.dispatch(decrementLoading());
		}, 1000);
	}

	async function getFormSubmissions(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new AdminApi(getConfig(token)).adminFormEntriesGet({formIdentifier: tableFilter});

			setTableData(cloneDeep(res).map((submission: FormEntriesResponse): IFormSubmissionsTableRow => {
				return {
					raw: submission,
					userName: submission.userName,
					...submission.formType,
					onEdit: selectEntryToEdit(submission),
					onDelete: selectEntryToDelete(submission),
				}
			}));
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function selectEntryToEdit(form: FormEntriesResponse): () => void {
		return () => {
			setFormToEdit(form);
		}
	}

	function cancelEditing(): void {
		setFormToEdit(null);
	}

	function onDoneEditing(): void {
		setFormToEdit(null);
		getFormSubmissions().then().catch();
	}

	function selectEntryToDelete(form: FormEntriesResponse): () => void {
		return () => {
			setFormToDelete(form);
		}
	}

	function cancelDeleting(): void {
		setFormToDelete(null);
	}

	function onDoneDeleting(): void {
		setFormToDelete(null);
		getFormSubmissions().then().catch();
	}

	return (
		<React.Fragment>
			<FormEditingModal
				submission={formToEdit}
				onCancel={cancelEditing}
				onDone={onDoneEditing}
			/>

			<FormDeletingModal
				submission={formToDelete}
				onCancel={cancelDeleting}
				onDone={onDoneDeleting}
			/>

			<Container className="my-4">
				<div className="mb-4">
					<h1>Form Submissions</h1>
					<p>
						On this page you can choose a specific type of form from the drop-down list below to see matching submissions by employees.
						Some tables are very wide and must be scrolled horizontally.
					</p>
				</div>
				<div>
					<Label for="formType">Select Form Type</Label>
					<Input type="select" id="formType" name="formType" placeholder="Select Form Type"
						   onChange={filterOnChange} value={tableFilter}>
						<option value={-1} disabled>Select Form</option>
						<hr/>
						<option value={FormIdentifier.FIELDWORK}>{formIdentifierParser(FormIdentifier.FIELDWORK)}</option>
						<option value={FormIdentifier.SPRAYAPP}>{formIdentifierParser(FormIdentifier.SPRAYAPP)}</option>
						<option value={FormIdentifier.COMPOSTTEMPERATURE}>{formIdentifierParser(FormIdentifier.COMPOSTTEMPERATURE)}</option>
						<option value={FormIdentifier.IRRIGATION}>{formIdentifierParser(FormIdentifier.IRRIGATION)}</option>
						<option value={FormIdentifier.HARVESTRECORD}>{formIdentifierParser(FormIdentifier.HARVESTRECORD)}</option>
						<option value={FormIdentifier.BEES}>{formIdentifierParser(FormIdentifier.BEES)}</option>
						<option value={FormIdentifier.NOTES}>{formIdentifierParser(FormIdentifier.NOTES)}</option>
					</Input>
				</div>

			</Container>

			<div className="my-5 px-1 px-lg-2">
				{tableFilter && (
					<React.Fragment>
						<p className="text-center">
							Default sorted by most recent submission.
							<br/>
							Click on an column header that has arrows to toggle sorting ascending/descending for that column.
						</p>
						<FormSubmissionsTable data={tableData} formType={tableFilter}/>
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(FormSubmissionsPage);
