import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Input, Label} from "reactstrap";
import {Bees, Notes} from "client";

interface IProps {
	token?: string;
	dispatch?: any;
	values: Notes;
	onChange(key: keyof Notes, value: any);
}

const NotesForm: React.FC<IProps> = (props: IProps) => {

	const {values} = props;

	function notesOnChange(e): void {
		props.onChange("notes", e.target.value)
	}

	return (
		<div>
			<div className="mb-3">
				<Label>Notes</Label>
				<Input type="textarea" value={values.notes} placeholder="Notes" onChange={notesOnChange}/>
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(NotesForm);
