"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldWorkToJSON = exports.FieldWorkFromJSONTyped = exports.FieldWorkFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function FieldWorkFromJSON(json) {
    return FieldWorkFromJSONTyped(json, false);
}
exports.FieldWorkFromJSON = FieldWorkFromJSON;
function FieldWorkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.AbstractFormFromJSONTyped(json, ignoreDiscriminator),
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'field': !runtime_1.exists(json, 'field') ? undefined : json['field'],
        'equipment': !runtime_1.exists(json, 'equipment') ? undefined : json['equipment'],
        'implement': !runtime_1.exists(json, 'implement') ? undefined : json['implement'],
        'crop': !runtime_1.exists(json, 'crop') ? undefined : json['crop'],
        'category': !runtime_1.exists(json, 'category') ? undefined : _1.FieldWorkCategoryFromJSON(json['category']),
        'compostPile': !runtime_1.exists(json, 'compostPile') ? undefined : json['compostPile'],
        'applicationRate': !runtime_1.exists(json, 'applicationRate') ? undefined : json['applicationRate'],
        'passesOver': !runtime_1.exists(json, 'passesOver') ? undefined : json['passesOver'],
        'fertilizeInput': !runtime_1.exists(json, 'fertilizeInput') ? undefined : json['fertilizeInput'],
        'seed': !runtime_1.exists(json, 'seed') ? undefined : json['seed'],
        'unit': !runtime_1.exists(json, 'unit') ? undefined : json['unit'],
        'ratePerAcre': !runtime_1.exists(json, 'ratePerAcre') ? undefined : json['ratePerAcre'],
        'lotNumber': !runtime_1.exists(json, 'lotNumber') ? undefined : json['lotNumber'],
        'acres': !runtime_1.exists(json, 'acres') ? undefined : json['acres'],
        'loadCount': !runtime_1.exists(json, 'loadCount') ? undefined : json['loadCount'],
        'commodity': !runtime_1.exists(json, 'commodity') ? undefined : json['commodity'],
        'checkedEquipment': !runtime_1.exists(json, 'checkedEquipment') ? undefined : json['checkedEquipment'],
    };
}
exports.FieldWorkFromJSONTyped = FieldWorkFromJSONTyped;
function FieldWorkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.AbstractFormToJSON(value),
        '_id': value._id,
        'startTime': value.startTime,
        'endTime': value.endTime,
        'field': value.field,
        'equipment': value.equipment,
        'implement': value.implement,
        'crop': value.crop,
        'category': _1.FieldWorkCategoryToJSON(value.category),
        'compostPile': value.compostPile,
        'applicationRate': value.applicationRate,
        'passesOver': value.passesOver,
        'fertilizeInput': value.fertilizeInput,
        'seed': value.seed,
        'unit': value.unit,
        'ratePerAcre': value.ratePerAcre,
        'lotNumber': value.lotNumber,
        'acres': value.acres,
        'loadCount': value.loadCount,
        'commodity': value.commodity,
        'checkedEquipment': value.checkedEquipment,
    };
}
exports.FieldWorkToJSON = FieldWorkToJSON;
