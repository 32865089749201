"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownListBodyToJSON = exports.DropdownListBodyFromJSONTyped = exports.DropdownListBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function DropdownListBodyFromJSON(json) {
    return DropdownListBodyFromJSONTyped(json, false);
}
exports.DropdownListBodyFromJSON = DropdownListBodyFromJSON;
function DropdownListBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dropdownLabel': json['dropdownLabel'],
        'alphabeticalOrder': !runtime_1.exists(json, 'alphabeticalOrder') ? undefined : json['alphabeticalOrder'],
    };
}
exports.DropdownListBodyFromJSONTyped = DropdownListBodyFromJSONTyped;
function DropdownListBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dropdownLabel': value.dropdownLabel,
        'alphabeticalOrder': value.alphabeticalOrder,
    };
}
exports.DropdownListBodyToJSON = DropdownListBodyToJSON;
