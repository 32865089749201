"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompostTemperatureToJSON = exports.CompostTemperatureFromJSONTyped = exports.CompostTemperatureFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CompostTemperatureFromJSON(json) {
    return CompostTemperatureFromJSONTyped(json, false);
}
exports.CompostTemperatureFromJSON = CompostTemperatureFromJSON;
function CompostTemperatureFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.AbstractFormFromJSONTyped(json, ignoreDiscriminator),
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'date': json['date'],
        'compostPiles': !runtime_1.exists(json, 'compostPiles') ? undefined : (json['compostPiles'].map(_1.CompostTempPerPileFromJSON)),
    };
}
exports.CompostTemperatureFromJSONTyped = CompostTemperatureFromJSONTyped;
function CompostTemperatureToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.AbstractFormToJSON(value),
        '_id': value._id,
        'date': value.date,
        'compostPiles': value.compostPiles === undefined ? undefined : (value.compostPiles.map(_1.CompostTempPerPileToJSON)),
    };
}
exports.CompostTemperatureToJSON = CompostTemperatureToJSON;
