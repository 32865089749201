import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Bees, DefaultApi, DropdownLabel, HarvestRecord} from "client";
import {Input, Label} from "reactstrap";
import {connect} from "react-redux";
import DateTimePicker from "./DateTimePicker";
import {momentStringDownToMinutes} from "../../utils/formatters";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import NumberFormat from "react-number-format";

interface IProps {
	token?: string;
	dispatch?: any;
	values: HarvestRecord;

	onChange(key: keyof HarvestRecord, value: any);
}

const HarvestRecordForm: React.FC<IProps> = (props: IProps) => {

	const {token, values} = props;
	const [fields, setFields] = useState<string[]>([]);
	const [crops, setCrops] = useState<string[]>([]);
	const [trucks, setTrucks] = useState<string[]>([]);
	const [units, setUnits] = useState<string[]>([]);
	const [destinations, setDestinations] = useState<string[]>([]);

	useEffect(() => {
		getDropDownValues().then().catch();
	}, []);

	async function getDropDownValues(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const fieldsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Field}});
			setFields(fieldsRes);

			const cropsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Crop}});
			setCrops(cropsRes);

			const trucksRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Truck}});
			setTrucks(trucksRes);

			const unitsRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Units}});
			setUnits(unitsRes);

			const destinationRes = await new DefaultApi(getConfig(token)).dropdownListPost({dropdownListBody: {dropdownLabel: DropdownLabel.Destination}});
			setDestinations(destinationRes);

		} catch (err) {
			props.dispatch(addError(err));
		}

		props.dispatch(decrementLoading());
	}

	function dateOnChangeHelper(key: keyof HarvestRecord): (e) => void {
		return (e) => {
			props.onChange(key, e);
		};
	}

	function onChangeHelper(key: keyof HarvestRecord): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			// @ts-ignore
			props.onChange(key, e.target.value);
		};
	}

	function onQuantityChange(e): void {
		props.onChange("quantity", e.floatValue);
	}

	return (
		<div>
			<div className="mb-3">
				<Label>Start Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.startTime)}
				</p>
				<DateTimePicker time={values.startTime} onChange={dateOnChangeHelper("startTime")}/>
			</div>

			<div className="mb-3">
				<Label>Field</Label>
				<Input type="select" value={values.field} onChange={onChangeHelper("field")}>
					<option value="">Make a selection...</option>
					<hr/>
					{fields.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Crop</Label>
				<Input type="select" value={values.crop} onChange={onChangeHelper("crop")}>
					<option value="">Make a selection...</option>
					<hr/>
					{crops.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Truck</Label>
				<Input type="select" value={values.truck} onChange={onChangeHelper("truck")}>
					<option value="">Make a selection...</option>
					<hr/>
					{trucks.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Quantity</Label>
				<NumberFormat
					allowLeadingZeros={false}
					placeholder="Quantity"
					value={values.quantity}
					customInput={Input}
					thousandSeparator={true}
					onValueChange={onQuantityChange}
					allowNegative={false}
				/>
			</div>

			<div className="mb-3">
				<Label>Units</Label>
				<Input type="select" value={values.units} onChange={onChangeHelper("units")}>
					<option value="">Make a selection...</option>
					<hr/>
					{units.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Load Time</Label>
				<p className="text-center">
					{momentStringDownToMinutes(values.loadTime)}
				</p>
				<DateTimePicker time={values.loadTime} onChange={dateOnChangeHelper("loadTime")}/>
			</div>

			<div className="mb-3">
				<Label>Destination</Label>
				<Input type="select" value={values.destination} onChange={onChangeHelper("destination")}>
					<option value="">Make a selection...</option>
					<hr/>
					{destinations.map((option) => {
						return (
							<option value={option}>{option}</option>
						);
					})}
				</Input>
			</div>

			<div className="mb-3">
				<Label>Notes</Label>
				<Input type="textarea" value={values.notes} placeholder="Notes" onChange={onChangeHelper("notes")}/>
			</div>

		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		token: store.metaStore.token,
		...props,
	};
})(HarvestRecordForm);
