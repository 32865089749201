"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Fraserland Organics
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 2.1.10-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractFormToJSON = exports.AbstractFormFromJSONTyped = exports.AbstractFormFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AbstractFormFromJSON(json) {
    return AbstractFormFromJSONTyped(json, false);
}
exports.AbstractFormFromJSON = AbstractFormFromJSON;
function AbstractFormFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': !runtime_1.exists(json, '_id') ? undefined : json['_id'],
        'owner': !runtime_1.exists(json, 'owner') ? undefined : json['owner'],
        'creationTime': !runtime_1.exists(json, 'creationTime') ? undefined : json['creationTime'],
        'lastTouched': !runtime_1.exists(json, 'lastTouched') ? undefined : json['lastTouched'],
        'notes': !runtime_1.exists(json, 'notes') ? undefined : json['notes'],
        'formIdentifier': !runtime_1.exists(json, 'formIdentifier') ? undefined : _1.FormIdentifierFromJSON(json['formIdentifier']),
    };
}
exports.AbstractFormFromJSONTyped = AbstractFormFromJSONTyped;
function AbstractFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'owner': value.owner,
        'creationTime': value.creationTime,
        'lastTouched': value.lastTouched,
        'notes': value.notes,
        'formIdentifier': _1.FormIdentifierToJSON(value.formIdentifier),
    };
}
exports.AbstractFormToJSON = AbstractFormToJSON;
