import React from "react";
import {Asset} from "client";
import {Button, ButtonGroup, Card, CardBody, CardHeader, CardImg, CardTitle} from "reactstrap";

interface IProps {
	value: Asset[];
	onChange: (arr: Asset[]) => void;
}

const MapInput: React.FC<IProps> = (props) => {

	function createMapView(item: Asset, i: number, arr: Asset[]) {

		function moveLeft() {
			const copy = [...props.value];
			copy[i - 1] = props.value[i];
			copy[i] = props.value[i - 1];
			props.onChange(copy);
		}

		function moveRight() {
			const copy = [...props.value];
			copy[i + 1] = props.value[i];
			copy[i] = props.value[i + 1];
			props.onChange(copy);
		}

		function deleteImage() {
			const copy = [...props.value];
			copy.splice(i, 1);
			props.onChange(copy);
		}

		return (
			<Card className="mx-1 align-self-start " key={i} style={{width: 350, maxHeight: 600, display: "inline-block", verticalAlign: "top"}}>
				<CardHeader>Index # {i}</CardHeader>
				<CardBody>
					<ButtonGroup>
						<Button color="danger" onClick={deleteImage}>Delete</Button>
						<Button color="primary" disabled={i === 0} onClick={moveLeft}>Move Left</Button>
						<Button color="primary" disabled={i === arr.length - 1} onClick={moveRight}>Move Right</Button>
					</ButtonGroup>
				</CardBody>
				<CardImg top width="100%" src={item.url} alt={"Map at index #" + i}/>
			</Card>
		)
	}

	return (
		<Card
			style={{
				display: "block",
				padding: 10,
				width: "auto",
				overflowX: "scroll",
				whiteSpace: "nowrap",
			}}
		>
			{props.value.map(createMapView)}
		</Card>
	)
}

export default MapInput;