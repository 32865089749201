import React, {useState} from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, Col, Row, Label, Input, CardFooter, ButtonGroup, Button, Spinner} from "reactstrap";
import {CustomCSV, AdminCustomCsvPutRequest, AdminApi} from "client";
import CustomCSVViewer from "./CustomCSVViewer";
import CustomCSVInputModal from "./CustomCSVInputModal";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {IStore} from "../redux/defaultStore";

interface IProps {
	token?: string;
	dispatch?: any;
	csvs?: CustomCSV[];
	onUpdate: () => void;
}

const CustomCSVFeed: React.FC<IProps> = (props) => {

	function createEntry(csv: CustomCSV, i: number) {
		return (
			<CSVEntry key={i} csv={csv} onUpdate={props.onUpdate}/>
		)
	}

	return (
		<Row>
			{
				!props.csvs ?
					<Spinner className="align-self-center"/> :
					props.csvs.map(createEntry)

			}
		</Row>
	)
}

const _CSVEntry: React.FC<{csv: CustomCSV, dispatch?: any, token?: string, onUpdate: () => void}> = ({csv, dispatch, token, onUpdate}) => {

	// viewing
	const [isOpen, setIsOpen] = useState(false);
	const close = () => setIsOpen(false);
	const open = () => setIsOpen(true);

	// updating
	const [updatedCSV, setUpdatedCSV] = useState<Partial<AdminCustomCsvPutRequest>>({name: csv.name, description: csv.description, id: csv._id});
	const [updateIsOpen, setUpdateIsOpen] = useState(false);
	const updateClose = () => setUpdateIsOpen(false);
	const updateOpen = () => setUpdateIsOpen(true);

	async function updateCSV() {
		dispatch(incrementLoading());
		try {
			await new AdminApi(getConfig(token)).adminCustomCsvPut(updatedCSV as AdminCustomCsvPutRequest);
			updateClose();
			onUpdate();
		} catch (err) {
			dispatch(addError(err));
		}
		dispatch(decrementLoading());
	}

	async function deleteCSV() {
		dispatch(incrementLoading());
		try {
			await new AdminApi(getConfig(token)).adminCustomCsvDelete({customCSV: csv});
			onUpdate();
		} catch (err) {
			dispatch(addError(err));
		}
		dispatch(decrementLoading());
	}

	return (
		<React.Fragment>
			<CustomCSVInputModal open={updateIsOpen} close={updateClose} value={updatedCSV} onChange={setUpdatedCSV} onSubmit={updateCSV}/>
			<CustomCSVViewer customCSV={csv} isOpen={isOpen} close={close}/>
			<Col md={4}>
				<Card className="m-3">
					<CardHeader>
						{csv.name}
					</CardHeader>
					<CardBody>
						<Label>ID</Label>
						<Input value={csv._id} disabled={true}/>
						<Label className="mt-1">Name</Label>
						<Input value={csv.name} disabled={true}/>
						<Label className="mt-1">Description</Label>
						<Input type="textarea" value={csv.description} disabled={true}/>
					</CardBody>
					<CardFooter>
						<ButtonGroup>
							<Button color="primary" onClick={open}>View</Button>
							<Button color="primary" onClick={updateOpen}>Update</Button>
							<Button color="danger" onClick={deleteCSV}>Delete</Button>
						</ButtonGroup>
					</CardFooter>
				</Card>
			</Col>
		</React.Fragment>
	)
}

const CSVEntry = connect((store: IStore, props: IProps) => {
	return {
		...props,
		token: store.metaStore.token
	}
})(_CSVEntry);


export default CustomCSVFeed;